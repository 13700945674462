<template>
  <v-container>
    <v-row>
      <v-col sm-6>
        <span class="pa-0 ma-0 text-h3">Rezervace</span>
      </v-col>
      <v-col sm-2>
        <v-switch label="Ukaž lidi" v-model="expandNames"></v-switch>
      </v-col>
      <v-col sm-2>
        <v-switch label="Zobrazit smazané" v-model="showDeleted"></v-switch>
      </v-col>
      <v-col sm-2>
        <span>
          <v-switch
            color="red"
            label="Zobrazit cizí"
            v-model="showOthersReservation"
          ></v-switch>
        </span>
      </v-col>
    </v-row>

    <v-row>
      <v-col sm-12>
        <span text-body>
          Seznam <span v-if="!showOthersReservation">Tvých</span
          ><span v-else>všech</span
          ><span v-if="!showDeleted"> aktivních</span> rezervací ode dneška dál.
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="reservations"
          :loading="loading"
          sort-by="from"
          class="elevation-1"
          :item-class="itemRowBackground"
        >
          <template v-slot:item.reservedBy="{ item }">
            {{ item.reservedByExpanded.name.print }}
            <span
              text-caption
              :inner-html.prop="item.createDate | formatCZDate"
            ></span>
          </template>
          <template v-slot:item.from="{ item }">
            <span :inner-html.prop="item.from | formatCZDate"></span>
          </template>
          <template v-slot:item.to="{ item }">
            <span :inner-html.prop="item.to | formatCZDate"></span>
          </template>
          <template v-slot:item.nights="{ item }">
            {{ item.nights }}
          </template>
          <template v-slot:item.members="{ item }">
            <span v-if="expandNames">
              <span v-for="(name, index) in item.memberNames" :key="index"
                >{{ name }}<br
              /></span>
            </span>
            <span v-else>{{ item.members.length }}</span>
          </template>
          <template v-slot:item.friends="{ item }">
            <span v-if="expandNames">
              <span v-for="(name, index) in item.friendNames" :key="index"
                >{{ name }}<br
              /></span>
            </span>
            <span v-else>{{ item.friends.length }}</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <span v-if="loading">
              <loader size="25" color="black" />
            </span>
            <span v-else>
              <v-tooltip
                bottom
                v-if="
                  (userProfile.id == item.reservedBy ||
                    userProfile.roles.manager) &&
                  showOthersReservation
                "
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    slot="activator"
                    v-bind="attrs"
                    v-on="on"
                    icon
                    color="red"
                    @click="
                      $router.push('/reservations/' + item.id + '?admin=1')
                    "
                    ><v-icon>mdi-pencil-outline</v-icon></v-btn
                  >
                </template>
                <span>Editovat za člena</span>
              </v-tooltip>
              <v-tooltip bottom v-if="userProfile.id == item.reservedBy">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    color="blue"
                    @click="$router.push('/reservations/' + item.id)"
                    ><v-icon>mdi-pencil-outline</v-icon></v-btn
                  >
                </template>
                <span>Editovat moji rezervaci</span>
              </v-tooltip>
              <v-tooltip
                bottom
                v-if="
                  (userProfile.id == item.reservedBy ||
                    userProfile.roles.manager) &&
                  item.status == 'ACTIVE'
                "
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    color="pink"
                    @click="deleteReservation(item.id)"
                    ><v-icon>mdi-delete-outline</v-icon></v-btn
                  >
                </template>
                <span>Nastavit rezervaci jako smazanou</span>
              </v-tooltip>
              <v-tooltip
                bottom
                v-if="
                  (userProfile.id == item.reservedBy ||
                    userProfile.roles.manager) &&
                  item.status == 'DELETED'
                "
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    color="green"
                    @click="renewReservation(item.id)"
                    ><v-icon>mdi-autorenew</v-icon></v-btn
                  >
                </template>
                <span>Obnovit smazanou rezervaci</span>
              </v-tooltip>
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const moment = require("moment");
// const fb = require('../firebaseConfig.js')
const helpers = require("../helpers.js");
import { mapState } from "vuex";
const logger = require("../fb-logger.js");
const dbReservations = require("../db-reservations.js");

export default {
  name: "reservation",
  computed: {
    ...mapState(["currentUser", "userProfile", "userLoading"]),

    // upravi rezervace pro tabulku
    reservationsTable() {
      return this.reservations.map((item) => {
        // console.log(item)

        let out = {
          reservedBy:
            item.reservedByExpanded.name.print +
            " (" +
            moment(item.createDate).format("DD.MM.YYYY") +
            ")",
          from: moment(item.from).format("DD.MM.YYYY"),
          to: moment(item.to).format("DD.MM.YYYY"),
          nights: item.nights,
        };
        if (this.expandNames) {
          out.members = item.membersExpanded.map((item) => item.name.print);
          out.friends = item.friendsExpanded.map((item) => item.name.print);
        } else {
          out.members = item.members.length;
          // out.friends = item.friends.length
        }

        return out;
      });
    },
  },
  watch: {
    showOthersReservation() {
      this.readReservations();
    },
    showDeleted() {
      this.readReservations();
    },
    userProfile() {
      this.readReservations();
    },
  },
  data() {
    return {
      showOthersReservation: false,
      showDeleted: false,
      expandNames: true,
      headers: [
        { text: "rezervoval", value: "reservedBy" },
        { text: "od", value: "from" },
        { text: "do", value: "to" },
        { text: "nocí", value: "nights" },
        { text: "členi", value: "members" },
        { text: "kamarádi", value: "friends" },
        { text: "Akce", value: "actions", sortable: false },
      ],
      pagination: { sortBy: "from", descending: false, rowsPerPage: -1 }, //HACK for sorting podle podle 'from'
      reservations: [],
      loading: false,
    };
  },
  methods: {
    deleteReservation(reservationId) {
      this.loading = true;
      logger.info("[RESERVATION-LIST] Deleting reservation:", reservationId);
      dbReservations.deleteReservation(reservationId).then(() => {
        this.$log.debug("Deleted", reservationId);
        this.loading = false;
        this.readReservations();
      });
    },
    renewReservation(reservationId) {
      this.loading = true;
      logger.info("[RESERVATION-LIST] Renew reservation:", reservationId);
      dbReservations.renewReservation(reservationId).then(() => {
        this.$log.debug("Reactivated", reservationId);
        this.loading = false;
        this.readReservations();
      });
    },

    readReservations() {
      this.$log.info(
        "reading reservations",
        this.showOthersReservation,
        this.showDeleted
      );
      this.loading = true;

      dbReservations
        .readFutureReservationList(
          this.userProfile,
          this.showOthersReservation,
          this.showDeleted
        )
        .then((out) => {
          // console.log(out)
          this.reservations = out;
          this.loading = false;
        });
    },
    itemRowBackground: function (item) {
        return item.status
    },
  },
  mounted() {
    this.readReservations();
  },
  filters: helpers.filters,
};
</script>

<style>

tr.ACTIVE td{
    background-color: #aaffaa55;
}

tr.DELETED td {
    background-color: #ffaaaa55;
}
</style>
