<template id="passwordChangePage">
    <v-container fluid grid-list-sm>
        <v-layout column v-if="userProfile && userProfile.name">
            <v-flex>
                <v-card>
                    <v-toolbar color="blue" dark>
                        <v-toolbar-title>
                            Změna hesla pro: {{userProfile.name.print}}
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-alert v-if="errorMessage" :value="true" type="error">
                        {{errorMessage}}
                    </v-alert>
                    <v-alert v-if="message" :value="true" type="info">
                        {{message}}
                    </v-alert>
                     <v-form v-model="valid">
                        <v-container>
                            <v-layout row wrap>
                                <v-flex xs12 sm12 ld12>
                                    <p>Zadej současné heslo</p>
                                    <v-text-field v-model="currentPassword" :rules="nameRules"
                                        label="Současné heslo" type="password" required></v-text-field>
                                </v-flex>

                                <v-flex xs12 sm12 ld12>
                                    <p>Zadej nové heslo a zkontroluj si, zda se Ti někdo nekouká přes rameno</p>
                                    <v-text-field v-model="newPassword" :rules="nameRules"
                                        label="Nové heslo" required></v-text-field>
                                </v-flex>
                            </v-layout>
                        </v-container>
                        <v-container>
                            <v-layout>
                                <v-flex d-flex>
                                    <v-btn color="info" @click="savePassword" :disabled=isSaving>
                                        <span v-if="isSaving"><loader size=40 colot="white"></loader></span>
                                        <span v-else>Uložit heslo</span>
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                        </v-container>
                     </v-form>
                </v-card>
            </v-flex>
        </v-layout>
        <div v-else>
            <loader size=200 color="orange"/>
        </div>
        {{errorMessage}}
        {{message}}
    </v-container>
</template>

<script>
    var logger = require("../fb-logger.js")
    import friendForm from '@/components/friendForm'
    const fb = require('../firebaseConfig.js')
    const dbPersons = require('../db-persons.js')
    const helpers = require('../helpers.js')
    
    import {
        mapState
    } from 'vuex'
import { usersCollection, currentUser } from '../firebaseConfig'

    export default {
        data() {
            return {
                valid: false,
                newPassword: "",
                currentPassword: "",
                uid: null,
                memberData: null,
                isSaving: false,
                
                message: undefined,
                errorMessage: undefined,

                //form validation
                nameRules: [
                    v => !!v || 'Musí být vyplněné',
                    v => v.length >= 6 || 'Heslo musí být delší než 6 znaků'
                ]
            }
        },
        
        computed: {
            ...mapState(['currentUser', 'userProfile', 'userLoading']),
        },
        methods: {
            savePassword() {
                console.log("1");
                this.isSaving = true;
                this.errorMessage=""
                this.message=""
                logger.info("Changing password for user "+this.currentUser.email);
                logger.info("Reauthenticationg user "+this.currentUser.email);

                fb.auth
                    .signInWithEmailAndPassword(this.currentUser.email,this.currentPassword)
                    .then(user=>{
                        logger.info("User autenticated as "+JSON.stringify(user));
                        logger.info("Changing password to new password .. ");
                        this.currentUser.updatePassword(this.newPassword).then(()=>{
                            this.message="Heslo změněno!"
                            this.isSaving=false;
                            logger.info("Password change sucess")

                        }).catch((err)=>{
                            logger.error("Password change error: >"+err.message+"<")
                            if(err.message=="The password is invalid or the user does not have a password."){
                                this.errorMessage = "Špatné staré heslo. Zkus to znovu."
                            } else {
                                this.errorMessage = err.message
                            }
                            this.isSaving=false;
                        });
                    }).catch(err=>{
                            logger.error("Reauth error:"+err)
                            if(err.message=="The password is invalid or the user does not have a password."){
                                this.errorMessage = "Špatné staré heslo. Zkus to znovu."
                            } else {
                                this.errorMessage = err.message
                            }
                            this.errorMessage = err.message
                            this.isSaving=false;
                    });            
            },
        },
        filters: helpers.filters
     }

</script>