<template id="mainPage">
  <v-container grid-list-md text-xs-left>
   <v-layout row wrap>
      <v-flex d-flex v-if="userProfile.showWellcomeMessage">
        <welcome-message/>
      </v-flex>
      <v-flex d-flex >
        <message-list visibilityMode="all"/>
      </v-flex>
  </v-layout>
  </v-container>
  
</template>

<script type="text/javascript">
  import messageList from '@/components/messageList'
  import welcomeMessage from '@/components/welcomeMessage'
  import { mapState } from 'vuex'

  export default {
    name: 'mainPage',
    computed:{
      ...mapState(['currentUser', 'userProfile', 'userLoading'])
    },  
    components: {
      'message-list':messageList,
      'welcome-message':welcomeMessage
    }
  }
</script>
