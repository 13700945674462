<template id="messageList">
  <div>
    <h1>
      Vzkazy
      <v-btn color="red" @click="addMessage" v-if="currentUser && (userProfile.roles.manager)">Nový vzkaz <v-icon>mdi-lock</v-icon></v-btn>
    </h1>
    <div v-for="msg in messages" :key="msg.id">
      <v-card raised v-if="editedMessage&&(msg.id == editedMessage.id)">
        <v-card-text>
        <v-form ref="form">
          <!--v-text-field
            v-model="editedMessage.media"
            label="Obrazek(URL)"
          ></v-text-field -->
          <v-text-field
            v-model="editedMessage.title"
            label="Titulek"
            required
          ></v-text-field>
          <!-- <v-textarea v-model="editedMessage.text" label="text"></v-textarea> -->
          
          <Editor 
               api-key="oe6d5zil00j2ctng1yo9n50yirduajge9m5ppubmlpzaf9tz" 
               :init="{
                 height: '500',
                 plugins: ['lists, advlist, table, link'],
                 menubar: false,
                 toolbar: 'bold | italic | underline | strikethrough |alignleft | aligncenter | alignright | alignjustify | styleselect | formatselect | bullist | numlist | outdent | indent | blockquote | undo | redo | removeformat | table | link'
                }"
               v-model="editedMessage.text"
          /> 
            
        </v-form>
        </v-card-text>
        <v-card-actions>
          <v-switch
                label="Publikovat veřejně"
                v-model="editedMessage.public"
            ></v-switch>
            <v-btn  color="cancel" @click="cancelEditing">Cancel  </v-btn>
            <v-btn  color="primary" @click="saveEditedMessage">Save <v-icon>mdi-lock</v-icon></v-btn>
        </v-card-actions>
     </v-card>
      <v-card  raised v-else> 
        <!-- v-card-media v-if="msg.media"  
          :src="msg.media"
          height="200px"
        ></v-card-media -->
        <v-card-title primary-title> {{msg.title}}</v-card-title>
        <v-card-text v-html="msg.text"></v-card-text>
        
        <v-card-actions>
          <v-btn v-if="canEdit" color="primary" @click="editMessage(msg)">Edit <v-icon>mdi-lock</v-icon></v-btn>
          <v-btn v-if="canEdit" color="red" @click="deleteMessage(msg)">Delete <v-icon>mdi-lock</v-icon></v-btn>
          <v-spacer></v-spacer>
          <v-chip caption float color="green" v-if="msg.visibility == 'public'">veřejný</v-chip>&nbsp;
          <v-chip caption float>{{msg.created | formatCZDateNoHtml}}</v-chip>
        </v-card-actions>
     </v-card>
     <br/>
     </div>
   </div>
  </template>

<script type="text/javascript">

const helpers = require('../helpers.js')

const fb = require('../firebaseConfig.js')
import moment from 'moment'
import {mapState} from 'vuex'

import Editor from '@tinymce/tinymce-vue';
var logger = require("../fb-logger.js")


export default {
  name: 'messageList',
  props: {
    visibilityMode: {
      type: String, //public nebo all
      default: "all"
    },
    count: {
      type: Number,
      default: 10
    }
  }, 
  components: {
    'Editor' : Editor
  },
  computed:{
    ...mapState(['userProfile']),
    ...mapState(['currentUser']),
    canEdit(){
      return this.currentUser!=null
    }
  },
  data: function () {
      return {
          messages: [],
          editedMessage: undefined
        }
  },
  methods:{
    addMessage(){
      var msg = {
        created: new Date(),
        updated: new Date(),
        title: "",
        text: "",
        image: "",
        public: false
      }
      let newMsgRef = fb.messagesCollection.doc();
      
      msg.id=newMsgRef.id
      newMsgRef.set(msg).then((res)=>{
        this.getMessages();
        this.editedMessage=msg  
      })
    },
    getMessages(){
      this.messages = []
      if(this.visibilityMode=='public'){
        fb.messagesCollection.where("visibility","==","public").orderBy("created","desc").get().then((res)=>{
            let msgs=[]
            res.forEach((item)=>{
              msgs.push(item.data())
            })
            this.messages = msgs.slice(0,this.count)
        }) 
      } else {
        fb.messagesCollection.orderBy("created","desc").get().then((res)=>{
            res.forEach((item)=>{
            this.messages.push(item.data())
          })
        }) 
      }  
    },
    editMessage(message){
      this.editedMessage = message;
      this.editedMessage.public = (message.visibility == "public")
    },
    deleteMessage(message){
      fb.messagesCollection.doc(''+message.id).delete().then(res=>{
        this.getMessages()
      }).catch(err => {
        logger.error("[MESSAGE LIST] Error delete message",err)
      })
    },
    cancelEditing(){
      this.editedMessage = null
    },
    saveEditedMessage(){
      this.editedMessage.visibility = this.editedMessage.public ? "public" : "private"; 
      fb.messagesCollection.doc(''+this.editedMessage.id).set(
        this.editedMessage
      ).then((res)=>{
        logger.info("[MESSAGE] Saved")
        this.getMessages()
        this.editedMessage = null
      })
    }
  },
  mounted () {
    this.getMessages();
  },
  filters: helpers.filters
}
</script>