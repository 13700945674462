<template>
  <v-app id="panenka">
    <v-navigation-drawer
      v-if="currentUser"
      v-model="drawer"
      app
      class="grey lighten-4"
      width="200"
    >
      <v-list-item>
        <v-list-item-content >
          <v-list-item-title class="title">  </v-list-item-title>
          <v-list-item-subtitle>  </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <navigationLeft />
    </v-navigation-drawer>

    <v-app-bar app v-if="currentUser" color="amber">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <span class="display-1 ml-2 mr-1"
        ><span v-if="$vuetify.breakpoint.smAndUp">LK&nbsp;</span>Panenka</span
      >
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn color="primary" v-on="on">
            <v-icon>mdi-account</v-icon>
            <span v-if="userLoading"><loader size="50" color="#000" /></span>
            <span v-else>{{ displayName }}</span>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="personalSettings">
            <v-list-item-title>Moje nastavení</v-list-item-title>
          </v-list-item>
          <v-list-item @click="passwordChange">
            <v-list-item-title>Změnit heslo</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-title>Odhlásit</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main
      style="
        background-image: url('/public/bg/bg1.jpg');
        background-size: cover;
        background-repeat: repeat-y;
      "
    >
      <v-container>
        <v-alert v-if="ieMessage" type="error" :value="true"
          >Používáš prohlížeč Internet explorer (resp. Edge). Snažil jsem se,
          aby v něm vše fungovalo, ale doporučuji přejít na Firefox nebo Chrome.
          Bude to rychlejší a krásnější.
        </v-alert>
        <router-view class="transwhite"></router-view>
      </v-container>
    </v-main>

    <v-footer class="pa-3">
      <v-spacer></v-spacer>
      <div>
        Pokud máte nějaké dotazy, tak pište:
        <a href="mailto:admin@lk-panenka.cz">admin@lk-panenka.cz</a>
      </div>
    </v-footer>
  </v-app>
</template>
<script>
import navigationLeft from "@/components/navigationLeft";

import { mapState } from "vuex";
const fb = require("./firebaseConfig.js");
const logger = require("./fb-logger.js");
const helpers = require("./helpers.js");

export default {
  components: {
    navigationLeft,
  },
  computed: {
    ...mapState(["userProfile", "currentUser", "userLoading"]),
    displayName: function () {
      try {
        return this.userProfile.name.print;
      } catch (err) {
        return "XXX";
      }
    },
  },
  data: () => ({
    drawer: null,
    ieMessage: false,
  }),
  methods: {
    logout() {
      fb.auth
        .signOut()
        .then(() => {
          this.$store.dispatch("clearData");
          this.$router.push("/login");
        })
        .catch((err) => {
          logger.error("FB auth error", err);
        });
    },
    personalSettings() {
      this.$router.push("/personalSettings");
    },
    passwordChange() {
      this.$router.push("/passwordChange");
    },
  },
  props: {
    source: String,
  },
  mounted() {
    this.ieMessage = helpers.detectIE();
    try {
      document.getElementById("NOSCRIPT").remove();
    } catch (err) {
      document.getElementById("NOSCRIPT").style.display = "none";
    }
  },
};
</script>

<style>
#keep main .container {
  height: 660px;
}

.v-navigation-drawer__border {
  display: none;
}

.text {
  font-weight: 400;
}

.transwhite {
  background-color: #ffffffaa;
}
</style>

