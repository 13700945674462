<template>
    <v-container grid-list-md ref="reservationOverview">
        <v-layout row wrap>
            <v-flex xs12>
                <h1>Přehled rezervací pro vedoucího</h1>
                <p>Pozor: máme blbě vyplněné děti, takže je to nutné kontrolovat</p>
            </v-flex>
            <v-flex xs5>
                <v-text-field v-model="dateFrom" label="od" hint="formát 2018-12-01"/> 
            </v-flex>
            <v-flex xs5>
                <v-text-field v-model="dateTo" label="do" hint="formát 2018-12-01"/> 
            </v-flex>
            <v-flex xs2>
                <v-btn @click="generateReport" color="primary" >Spusť</v-btn> 
            </v-flex>
            <v-flex xs12 v-if="loading">
                <loader size=100 color="#0000" />
            </v-flex>
            <v-flex v-else xs12>
                    <v-list three-line expand>
                        <template v-for="(res) in reservations">
                            <v-list-item
                                :key="res.id"    
                                :dense="true"
                                >
                                <v-list-item-content>
                                    <v-list-item-title>{{res.from | formatCZDateNoHtml}} - {{res.to | formatCZDateNoHtml}}</v-list-item-title>
                                    <v-list-item-sub-title>
                                        <span class='body-1' v-for="member in res.membersExpanded" :key="member.id">
                                            <strong>{{member.name.print}}, </strong>
                                        </span><br/>
                                        <span class='body-1' v-for="friend in res.friendsExpanded" :key="friend.id">
                                            {{friend.name.print}},
                                        </span>     
                                    </v-list-item-sub-title> 
                                    
                                </v-list-item-content>
                                <v-list-item-action>
                                    {{res.calculate}} = {{res.price}}
                                </v-list-item-action>
                            </v-list-item>
                            <v-divider :key="'d'+res.id"></v-divider>
                        </template>
                    </v-list>
            </v-flex>
            <v-flex xs12>
                <h2>Celkem: {{totalPrice}}</h2>
                <v-btn @click="generatePdf" color="primary" >PDF</v-btn> 
            </v-flex>
        </v-layout>
    </v-container>    
</template>

<script>
const dbReservations = require("../db-reservations.js")
const helpers = require("../helpers.js")

const pdfmake = require('pdfmake/build/pdfmake.js'); 
const pdffints = require('pdfmake/build/vfs_fonts.js');

export default {
    data(){
        return {
            dateFrom:'2018-12-20',
            dateTo:'2018-12-27',
            reservations: null,
            totalPrice:0,
            loading: false
        }
    },
    methods:{
        generateReport(){
            this.loading = true;
            this.$log.debug("Generating report")
            let from=new Date(this.dateFrom)
            let to= new Date(this.dateTo)
            dbReservations.getReservationsBetween(from, to).then(data =>{
                this.$log.debug(data)
                this.reservations = data
                this.loading = false
                this.totalPrice = 0
                data.forEach(res=>{
                    res.price = res.nights*(res.members.length*50 + res.friends.length*150);
                    this.totalPrice+=res.price
                    res.calculate = res.nights+"*("+res.members.length+"*50,- + "+res.friends.length+"*150,-)"
                })
            })
        },
        generatePdf(){
            const html = this.$refs.reservationOverview.$el.innerHTML
            this.$log.debug(html)
        }
    },
    filters: helpers.filters


}
</script>


