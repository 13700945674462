<template>
    <v-card>
        <v-card-title primary-title>
          <div>
            <h3 class="headline mb-0">Vítej v nové verzi Panenky</h3>
            <div>Je tu pár změn o kterých bys měl vědět</div>
          </div>
        </v-card-title>
        <v-card-text>
                
                <h2>Login, heslo, odhlášení a tak</h2>
                <p>Odhlásit se můžeš kliknutím na svoje jméno vpravo nahoře. <v-btn color="amber"><i aria-hidden="true" class="v-icon material-icons">account_box</i>{{userProfile.name.first}} {{userProfile.name.last}}</v-btn></p>
                
                <p>Pokud při přihlášení zakrtneš "Pamatovat si mně", 
                tak tě systém sám přihlásí, pokud používáš stejný počítač či prohlížeč. Není to ale dokonalé. Po delší době se stejně budeš muset přihlásit znova. <strong>Pozor! Každý do použije stejné zařízení bude přihlášen stejně jako Ty.</strong> 
                </p>
                <p>Pokud heslo zapomeneš, stačí kliknout na <v-btn >Zaslat heslo</v-btn> na přihlašovací stránce. Do emailu Ti dorazí odkaz na vytvoření nového</p>

                <h2>Emaily</h2>
                <p>Když klikneš vpravo nahoře na <v-btn color="amber"><i aria-hidden="true" class="v-icon material-icons">account_box</i>{{userProfile.name.first}} {{userProfile.name.last}}</v-btn>, otevře se nastavení. Tam si můžeš zvolit, jaké emaily chceš dostávat.</p>

                <h2>Kamarádi</h2>
                <p>V kamarádech byl děsný, ale děsný bordel. Takže ne všichni se přenesli ze staré databáze. Raději se mrkni do svého nastavení (opět schované pod jménem)</p>
<!--
                <h2>Skupiny</h2>
                <p>Je tu nová možnost jak zapisovat známé. Stačí patřit do nějaké <strong>skupiny</strong>. </p>
-->

        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-checkbox v-model="check"  label="Jasně, Příště už nezobrazovat" @click="hideWelcomeMessage" />
        </v-card-actions>
    </v-card>
</template>

<script>
const fb = require('../firebaseConfig.js')
import { mapState } from 'vuex'

export default {
    name: "welcomeMessage",
    data() {
        return {
            check: false
        }
    },
    computed: {
        ...mapState(['currentUser', 'userProfile', 'userLoading']),
    },
    methods:{
        hideWelcomeMessage(){
            fb.membersCollection
            .doc(this.userProfile.id)
            .update({"showWellcomeMessage":false})
            .then(()=>{
                this.$store.dispatch("fetchUserProfile")
            })
        }
    }
}
</script>

