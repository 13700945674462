<template id="navigationLeft">
  <v-layout column space-between>
    <v-list dense class="grey lighten-4" v-if="userProfile">
      <template v-for="(item, i) in items">
        <v-list-item
          :key="i"
          :to="item.link"
          v-if="
            userProfile != undefined &&
            userProfile.roles &&
            userProfile.roles[item.role]
          "
        >
          <v-list-item-content>
            <v-list-item-title>
              <v-icon>{{ item.icon }}</v-icon
              ><span class="text-button"> {{ item.text }}</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
    <v-divider></v-divider>
    <v-card class="d-flex pa-2"  tile>
      <v-btn class="d-flex" color="primary" @click="$router.push('/reservations/new')"
        >Nová rezervace</v-btn
      >
    </v-card>
    <v-card class="d-flex pa-2" tile>
      <v-btn
        class="d-flex"
        v-if="userProfile.roles && userProfile.roles.manager"
        color="red"
        @click="$router.push('/reservations/new?admin=1')"
        >Cizí rezervace&nbsp;<v-icon>mdi-lock</v-icon>
      </v-btn>
    </v-card>

    <v-divider></v-divider>
    <v-spacer height="300"></v-spacer>
    <helpBox></helpBox>
  </v-layout>
</template>

<script>
import helpBox from "@/components/helpBox";
import { mapState } from "vuex";

export default {
  name: "navigationLeft",
  components: {
    helpBox,
  },
  computed: {
    ...mapState(["currentUser", "userProfile", "userLoading"]),
  },
  data: () => ({
    items: [
      { deliminer: "" },
      { icon: "mdi-home", text: "Nástěnka", link: "/home", role: "member" },
      {
        icon: "mdi-calendar",
        text: "Kalendář",
        link: "/calendar",
        role: "member",
      },
      {
        icon: "mdi-snowflake",
        text: "Rezervace",
        link: "/reservations",
        role: "member",
      },
      {
        icon: "mdi-account-multiple",
        text: "Členové",
        link: "/members",
        role: "member",
      },
      {
        icon: "mdi-account-multiple-outline",
        text: "Kamarádi",
        link: "/friends",
        role: "manager",
      },
      {
        icon: "mdi-information-outline",
        text: "Informace",
        link: "/documents",
        role: "member",
      },
    ],
  }),
};
</script>

