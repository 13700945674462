import Vue from 'vue'
import App from './App.vue'
import vuetify from '@/plugins/vuetify' 

import { store } from './store.js'
const fb = require('./firebaseConfig.js')
import router from './router'

import loaderComponent from '@/components/loaderComponent.vue'
import VuetifyConfirm from 'vuetify-confirm'
import VueLogger from 'vuejs-logger';
import 'babel-polyfill'

console.log("LK-panenka version 1.0.1");

// Vue.use(vuetify)
Vue.config.productionTip = true
Vue.component('loader',loaderComponent)

Vue.use(VuetifyConfirm, {
  vuetify,
  buttonTrueText: 'Jasně',
  buttonFalseText: 'Neee!',
  width: 250,
  property: '$confirm'
})

import VueAnalytics from 'vue-analytics'
Vue.use(VueAnalytics, {
  id: 'UA-162529-2',
  router,
  autoTracking: {
    exception: false,
    exceptionLogs: false,
    pageviewTemplate(route) {
      return {
        page: route.path,
        title: document.title,
        location: window.location.href

      }
    }
  }
})

const isProduction = process.env.NODE_ENV === 'production';

const options = {
    isEnabled: true,
    logLevel : isProduction ? 'error' : 'debug',
    stringifyArguments : false,
    showLogLevel : true,
    showMethodName : true,
    separator: '|',
    showConsoleColors: true
};
Vue.use(VueLogger, options);

let app
fb.auth.onAuthStateChanged(user => {
    try{
       Vue.$ga.set('userId', user.uid);
       Vue.$ga.set('dimension1', user.uid);
       
    } catch(e){
     
    }
    //logger.log("fb.auth.statechanged:"+JSON.stringify(user))
    if (!app) {
      app = new Vue({
        el: '#app',
        router,
        store,
        vuetify,
        render: h => h(App)
      })
    }
})
