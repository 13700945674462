<template id="test">
  <v-container fluid grid-list-md>
    <v-layout row wrap>
      <v-flex d-flex>
        <reservationForm 
            v-if = "$route.params.id=='new'"
            v-bind:dateFromProp="today"
            mode="ADD"
            :admin = "admin"
        />
        <reservationForm 
            v-if = "($route.params.id!='new')&&reservation"
            mode = "EDIT"
            :admin = "admin"
            :reservation = "reservation"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import reservationForm from '@/components/reservationForm' 
const dbReservations = require('../db-reservations.js')

export default {
    components:{
      reservationForm
    },
    data(){
      return{
        reservation: null,
        admin:false
      }
    },
    computed:{
       today: function(){
         return new Date()
       }
    },
    watch:{
      $route: function(val){
          this.admin=(this.$route.query.admin==1)
      }
    },
    mounted(){
        this.admin=(this.$route.query.admin==1)
        
        if(this.$route.params.id!="new"){
           
            dbReservations.getReservationById(this.$route.params.id).then(res=>{
              this.reservation = res
            })
        } else {
            
            this.reservation = null;
        }
    }


}
</script>
