<template id="signUpPage">
  <v-container fluid grid-list-md>
    <v-layout row wrap>
      <v-flex d-flex xs12 sm6 md6>
        <v-stepper v-model="krok">
            <v-stepper-header>
                <v-stepper-step :complete="krok > 1" step="1">Starý login</v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="krok > 2" step="2">Registrace</v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="3">Hotovo!</v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
                <v-stepper-content step="1">
                    <v-card class="elevation-12">
                        <v-form v-model="valid1">
                        <v-toolbar dark color="amber">
                            <v-toolbar-title>Zadej staré přihlašovací údaje</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <transition name="fade">
                            <v-alert v-if="errorMessage" :value="true" type="error">
                                {{errorMessage}}
                            </v-alert>
                        </transition>
                        <v-card-text>
                                <p>
                                    Zadej přihlašovací jméno a heslo ze starého systému.
                                </p>
                                <v-divider/>
                                <v-text-field v-model.trim="oldLogin" :rules="oldLoginRules" name="oldLogin" label="starý login 'franta.novak'"
                                    type="text"></v-text-field>
                                <v-text-field v-model.trim="oldPassword" :rules="notEmptyRules" id="oldPassword"  name="oldPassword"
                                    label="staré heslo" type="password" @keyup.enter.native="checkOldLogin  "></v-text-field>
                        
                        </v-card-text>
                        <v-card-actions>
                            <v-btn color="secondary" @click="goToLogin">Zpět na login</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="checkOldLogin" :disabled="!valid1">
                                <span v-if="loading"><loader size="50" color="white" /></span>
                                <span v-else>Zkontrolovat</span>
                            </v-btn>
                        </v-card-actions>
                        </v-form>
                    </v-card>
                </v-stepper-content>
            </v-stepper-items>
            <v-stepper-items>
                <v-stepper-content step="2">
                    <!-- krok 2-->
                    <v-card class="elevation-12">
                        <v-form v-model="valid2" lazy-validation>
                        <v-toolbar dark color="amber">
                            <v-toolbar-title>Zadej nové údaje</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <transition name="fade">
                            <v-alert v-if="errorMessage" :value="true" type="error">
                                {{errorMessage}}
                            </v-alert>
                        </transition>
                        <v-card-text>
                                <p v-if="login">Jako nový login je nastaven email ze strého webu: <strong>{{oldEmail}}</strong>.
                                    Klidně jej ale změň na nějaký jiný, aktuální. </p>
                                <p v-else>Ve starém profilu nemáš žádný email. Prosím zadej Tvůj používaný email jako nové
                                    přihlašovací jméno.  
                                </p>
                                <v-divider/>
                               <v-spacer/>
                                <v-text-field v-model.trim="login" :rules="emailRules" required prepend-icon="person" name="login" label="nový login musí být email"
                                    type="text"></v-text-field>
                                <v-text-field v-model.trim="password1" :rules="passRules" required id="password1" prepend-icon="lock" name="password1"
                                    label="heslo" type="password"></v-text-field>
                                <v-text-field v-model.trim="password2" :rules="passRules" required id="password2" prepend-icon="lock" name="password2"
                                    label="heslo pro kontrolu" type="password" @keyup.enter.native="makeRegistration"></v-text-field>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn color="secondary" @click="goToLogin">
                                Zpět na login
                            </v-btn>
                            <v-spacer></v-spacer>

                            <v-btn color="primary" @click="makeRegistration" :disabled="!valid2">
                                <span v-if="loading"><loader size="50" color="white" /></span>
                                <span v-else>Vytvořit nový účet</span>
                            </v-btn>
                        </v-card-actions>
                        </v-form>
                    </v-card>

                </v-stepper-content>
            </v-stepper-items>
            <v-stepper-items>
                <v-stepper-content step="3">
                    <v-card class="elevation-12">
                        <transition name="fade">
                            <v-alert v :value="true" type="success">
                                Uživatel {{oldLogin}} aktivován jako {{login}}
                            </v-alert>
                        </transition>
                        <v-card-text>
                            <h3>Hotovo</h3>
                            <p>Od teď se můžeš přihlašovat jako <strong>{{login}}</strong> s heslem <strong>{{password1}}</strong></p>
                            <p>Pokud heslo zapomeneš, můžeš si nechat zaslat nové na zadaný email.</p>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn color="primary" @click="goToLogin">Začít pracovat s Panenkou... </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-stepper-content>
            </v-stepper-items>

        </v-stepper>
      </v-flex>
      <v-flex d-flex xs12 sm6 >
          <v-card>
              <v-card-title>
              <h2>Jak na to?</h2>
              </v-card-title>
              <v-card-text>
                  <p>
                      Nová verze rezervačního systému se liší v jednom podstatném bodě: <strong>staré přihlašovací jméno je nahrazeno emailem.</strong> To má své výhody. Například je možné zaslat zapomenuté heslo. Ale je třeba si nejprve účet aktivovat. 
                  </p>
                  <ol>
                     <li>
                         Přihlaš se starým jménem a heslem. Login je typu <strong>jmeno.prijmeni</strong>.
                     </li>
                     <br>
                     <li>       
                        Pokud se to povede zadej email, který budete nově používat pro přihlášení a nové heslo. To musí být alespoň 6 znaků dlouhé.
                     </li>
                     <br>
                     <li>
                         Hotovo. Nyní se můžeš přihlásit do nového systému.
                    </li>
                    </ol>
                    <br>
                    <p>
                        Pokud budete mít jakékoliv problémy, ozvěte se mi na email: <a href="mailto:jiri.stepan@activate.cz">jiri.stepan@activate.cz</a>
                    </p>
              </v-card-text>
          </v-card>
      </v-flex>
    </v-layout>
 </v-container>

</template>

<script>
    import axios from 'axios'
    const fb = require('../firebaseConfig.js')
    var logger = require("../fb-logger.js")

    export default {
        name: 'signUpPage',
        data() {
            return {
                krok: 1,
                loading: false,
                
                errorMessage: '',
                message: "start",

                oldLogin: '',
                oldPassword: '',

                login: '',
                password1: '',
                password2: '',

                oldEmail: null,
                personData: {},

                //form validation
                valid1: false,
                valid2: false,

                oldLoginRules: [
                    v => !!v || 'Musí být vyplněné',
                    v => v && /^([\w]{1,20}\.[\w]{1,20}|admin)/.test(v) || 'login je ve typu jmeno.prijmeni'
                ],
                notEmptyRules:[
                    v => !!v || 'Musí být vyplněno' 
                ],
                passRules:[
                    v => !!v || 'Musí být vyplněno' ,
                    v => v && v.length >=  6 || 'Heslo by mělo mít 6 a více znaků' 
                ],
                emailRules: [
                    v => !!v || 'Musí být vyplněno',
                    v => /.+@.+/.test(v) || 'Musí být platný email.'
                ]
            }
        },
        methods: {
            checkOldLogin() {
                const payload = {
                    login: this.oldLogin,
                    password: this.oldPassword
                }
                this.errorMessage = ''
                this.loading = true;
                logger.info("[SIGNUP] Checking old login", payload)

                axios.post(fb.urlPrefix + '/checkOldLogin', payload).then((data) => {
                    if (data.data.status && (data.data.status == "ok")) {
                        this.personData = data.data.data; //WOW!
                        logger.info("[SIGNUP] Found old login:", this.personData)
                        this.krok = 2
                        this.loading = false;
                        if (this.personData.email != '') {
                            this.login = this.personData.email
                            this.oldEmail = this.personData.email
                        }

                    } else if (data.data.status && (data.data.status == "error")) {

                        let emsg = data.data.message;
                        logger.info("[SIGNUP] Old login failed:", emsg)
                        if(emsg=="unknown login"){
                            this.errorMessage="Tenhle login neznám. Zedej to jak jsi se přihlašovali na starou panenku."
                        } else if(emsg=="bad password"){
                            this.errorMessage="Chybné heslo. Zadej heslo pro starý systém."
                        } else if(emsg=="already registered"){
                            this.errorMessage="Uživatel "+this.oldLogin+" si již nový účet aktivoval. Zkus si nechat zaslat zapomenuté heslo."
                        } else {
                            this.errorMessage = emsg;
                        }
                        this.loading = false;
                    } else {
                        this.errorMessage = "Stala se chyba."
                        this.loading = false;
                    }

                }).catch((err) => {
                    logger.err("[SIGNUP]",err)
                    this.errorMessage = "Stala se chyba:" + err
                    this.loading = false;
                })

            },
            makeRegistration() {
                if ((this.password1.length == 0) || (this.password1 != this.password2)) {
                    this.errorMessage = "Chyba. Hesla se neshodují"
                } else {

                    fb.auth.createUserWithEmailAndPassword(this.login, this.password1).then(userres => {
                        logger.info("[SIGNUP] New user", userres.user.uid)
                        let newPersonData = this.personData;
                        newPersonData['firebaseUserId'] = userres.user.uid
                        newPersonData['email'] = this.login
                        newPersonData['showWellcomeMessage'] = true

                        // console.log("Updating member profile " + newPersonData.id + "->" + JSON.stringify(newPersonData));
                        fb.membersCollection.doc(newPersonData.id).set(newPersonData).then((res) => {
                            // console.log("Member updated");
                            this.$store.dispatch('clearData');
                            this.krok = 3;
                        }).catch(err => {
                            logger.error("[SIGNUP] Error updatng member",err)
                            this.errorMessage = "Error updatng member:" + err
                        })

                    }).catch(err => {
                        this.errorMessage = fb.translateAuthError(err)
                    })
                }
            },
            goToLogin() {
                document.location="/home";
            }
        }
    }
</script>