<template>
    <v-form v-if="friend && friend.name"  v-model="valid" ref="form" lazy-validation>
        <v-container>
            <v-row>
                <v-col sm="4">
                    <v-text-field v-model="friend.name.first" :counter="20" :rules="nameRules" label="Jméno"
                            required></v-text-field>
                </v-col>
                <v-col sm="4">
                        <v-text-field xs12 sm6 ld6 v-model="friend.name.last" :counter="20" :rules="nameRules" label="Příjmení"
                            required></v-text-field>
                </v-col>
                <v-col sm="4">
                        <v-text-field xs12 sm6 ld6 v-model="friend.id_number" :counter="20" :rules="nameRules" label="Číslo občanky"
                            required></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col sm="12">
                        <v-checkbox xs12 sm6 ld6 v-model="friend.child" label="Dítě"></v-checkbox>
                </v-col>
            </v-row>
            <v-row>

                <v-col sm="4">
                    <v-btn v-if="mode=='UPDATE'" color="pink" @click="deleteFriend" >
                        <v-icon>mdi-delete-outline</v-icon> SMAZAT
                    </v-btn>
                </v-col>
                <v-col></v-col>
                <v-col sm="4">
                    <v-btn v-if="mode=='UPDATE'" color="info" @click="updateFriend" :disabled="(!valid)||(!dataOk)">Aktualizovat</v-btn>
                    <v-btn v-if="mode=='ADD'" color="info" @click="addFriend" :disabled="(!valid)||(!dataOk)">přidat kamaráda</v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
    const fb = require('../firebaseConfig.js')
    const logger = require('../fb-logger.js')

    import {
        mapState
    } from 'vuex'

    export default {
        name: "friendForm",
        computed: {
            ...mapState(['currentUser', 'userProfile', 'userLoading']),
            dataOk: function(){
                return (this.friend.name.first  && this.friend.name.last)
          }
        },
        data() {
            return {
                 friend: null,
                 valid: false,
                //form validation
                nameRules: [
                    v => !!v || 'Musí být vyplněné',
                    v => v && v.length <= 10 || 'Name must be less than 10 characters'
                ],
                emailRules: [
                    v => !!v || 'Musí být vyplněné',
                    v => /.+@.+/.test(v) || 'E-mail must be valid'
                ],
                notEmpty: [
                    v => !!v || 'Musí být vyplněné',
                ]
            }
        },
        props: {
            editedFriend: {
                type: Object,
                default: null
            },
            mode: { //ADD nebo UPDATE
                type: String,
                default: "ADD"
            }
        },
        watch:{
            mode: function(newval, oldval){
                this.$log.debug("Init blank friend ")
                if(newval=="ADD") this.initBlankFriend();
                if(newval=="UPDATE") this.friend = this.editedFriend;
            },

            editedFriend: function( newval, oldval){
                if(newval) this.friend = newval
            }
        },
        
        methods: {
            cancelChanges(){
                 this.friend = this.editedFriend
            },
            deleteFriend(){
                this.$confirm('Opravdu smazat?').then(res => {
                    this.$log.debug("Confirm result", res)
                    if(res){
                        fb.friendsCollection.doc(this.friend.id).delete().then(snap=>{
                            this.initBlankFriend();
                            this.$store.dispatch("fetchUserProfile"); 
                            this.$emit("friend-deleted",this.friend)                     
                        })
                    }   
                })
            },
            addFriend() {
                // this.$log.debug("[friendForm] addFriend:",this.friend)
                logger.info("[friendForm] addFriend:",this.friend)
                let newRef = fb.friendsCollection.doc();
      
                this.friend.id=newRef.id
                this.friend.grantedBy=this.userProfile.id
                this.friend.name.print = this.friend.name.first+" "+this.friend.name.last
                
                newRef.set(this.friend).then((res)=>{
                    this.$emit("friend-added",this.friend)
                    this.initBlankFriend();
                    this.$store.dispatch("fetchUserProfile");
                })
            },
            updateFriend() {
                this.friend.name.print = this.friend.name.first+" "+this.friend.name.last
                fb.friendsCollection.doc(this.friend.id).set(this.friend).then(snap =>{
                    this.$store.dispatch("fetchUserProfile");
                    this.$emit("friend-edited",this.friend) 
                })
                
            },
            initBlankFriend(){
                this.friend = {
                    grantedBy: this.userProfile.id,
                    name:{
                        first:'',
                        last:''
                    },
                    id_number:'',
                    child: false
                }
            }
        },
        mounted(){
            this.initBlankFriend();
        }
    }
</script>