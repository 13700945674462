<template id="helpBox">
    <v-card flat>
        <v-card-text>
            <p v-if="actualPage=='/personalSettings'">
                Můžete editovat svoje nastavení
                ..
                ..

            </p>
            <p v-if="(actualPage=='/')||(actualPage=='/calendar')">
                Klikněte na den, pro zobrazení obsazenosti chaty.
            </p>    
            <p v-if="actualPage=='/reservations'">
                <strong>Seznam rezervací</strong><br>
                Seznam plánovaných rezervací. Ukazuje budoucí rezervace a to včetně smazaných.
                <br>
                Rezervaci můžete smazat a smazanou naopak znovu oživit kliknutím na akce vpravo
            </p>
            <p v-if="actualPage=='/reservations/new'">
                <strong>Nová rezervace</strong><br>
                Zadejte den kdy přijdete a den kdy odjedete.<br/>
                <strong>Příklad:</strong> Jedu na víkend. Den příjezdu je pátek, den odjezdu je neděle a jste na chatě dvě noci <br/>
                Poté přidejte další členy a kamarády. Pro přidání a odebrání použijte:<br/>
                <v-btn flat icon color="green" ><v-icon>mdi-checkbox-blank-outline</v-icon></v-btn> a   <v-btn flat icon color="red"><v-icon>mdi-delete</v-icon></v-btn>
           
                 <br>Pokud chcete kamarády odebírat udělejte to ve svém <router-link to="/personalSettings">nastavení</router-link>         
                 <br>Po uložení by vám měl přijít email se shrnutím rezervace
            </p>
        </v-card-text>
    </v-card>
</template>

<script>

export default {
    name: "helpBox",
    computed: {
        actualPage: function(){return this.$route.path}
    },
}
</script>

