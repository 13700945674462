const fb=require("./firebaseConfig.js")

module.exports={
    getAllMembers:getAllMembers,
    getAllFriends:getAllFriends,
   
    prepareAllMembersById:prepareAllMembersById,
    prepareMemberNamesList:prepareMemberNamesList,
    expandMembersFromOldId:expandMembersFromOldId,
    expandFriendsFromOldId:expandFriendsFromOldId,

    storeUserActivity:storeUserActivity,

}

//store user event
function storeUserActivity(userEmail,eventType, eventSource="", eventData={}){
    return new Promise((resolve, reject)=>{
        let payload = {
            timestamp: new Date(),
            userEmail: userEmail,
            eventType: eventType,
            eventSource: eventSource,
            eventData: eventData
        }
        console.log(payload)
        fb.userActions.add(payload)
        .then(function(docRef) {
            console.log("Added userAction:"+JSON.stringify(payload))
            resolve(docRef.id);
        })
        .catch(function(error) {
            console.error("Error adding document: ", error);
            reject("Error adding new user log");
        });
    })
}

//vrati vsechny cleny. jako pole
function getAllMembers() {
    return new Promise((resolve, reject)=>{
        let allMembers = []
        
        fb.membersCollection.where("active","==",true).orderBy("name.last").get().then((snapshot) => {
            snapshot.forEach((doc) => {
                let data = doc.data()
                if(data.id != "mem1"){
                    allMembers.push(data)
                }
            })
            
            resolve(allMembers)
        })
    })
}

//vrati vsechny cleny. jako pole
function getAllFriends() {
    return new Promise((resolve, reject)=>{
        let out = []
        
        fb.friendsCollection.orderBy("name.last").get().then((snapshot) => {
            snapshot.forEach((doc) => {
                let data = doc.data()
                out.push(data)
            })
            // console.log("All members loaded. Total:" + this.allMembers.length)
            resolve(out)
        })
    })
}

//prevede na hashmapu dle id
function prepareAllMembersById(members){
    let out={}
    for(let i=0;i<members.length;i++){
        out[members[i].id] = members[i]
    }
    return out
}

//vratil pole {id, jmeno} napriklad pro seznamy apod.
function prepareMemberNamesList(members){
    return members.map(
        (item)=> {
            return {
                memberId: item.id,
                memberText: item.name.print
            }
    })
}

function expandMembersFromOldId(arr) {
    //console.log("Expanding " + JSON.stringify(arr));
    return new Promise((resolve, reject) => {
        let tasks = []
        let out = []
        if (!(arr instanceof Array)) resolve([])
        arr.forEach((oldid) => {
            tasks.push(fb.membersCollection.doc("mem" + oldid).get())
            tasks.push(fb.membersCollection.doc(oldid).get())
        })
        Promise.all(tasks).then(snapshots => {
            snapshots.forEach((doc) => {
                if (doc.exists) {
                    if(doc.data().active){
                        out.push(doc.data())
                    }
                }
            })
            resolve(out)
        })
    })
}

function expandFriendsFromOldId(arr) {
    //console.log("Expanding " + JSON.stringify(arr));
    return new Promise((resolve, reject) => {
        let tasks = []
        let out = []
        if (!(arr instanceof Array)) resolve([])
        arr.forEach((oldid) => {
            tasks.push(fb.friendsCollection.doc("fri" + oldid).get())
            tasks.push(fb.friendsCollection.doc(oldid).get())
        })
        Promise.all(tasks).then(snapshots => {
            snapshots.forEach((doc) => {
                if (doc.exists) {
                    if(doc.data().active){
                        out.push(doc.data())
                    }
                }
            })
            resolve(out)
        })
    })
}