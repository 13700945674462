
<template id="calendarPage">
<div>
  <ReservationCalendar v-on:dateSelected:date="selectDate($event)"/>
  <DayOverview :selected-date="selectedDate"></DayOverview>
</div>
</template>

<script>
const helpers = require("../helpers.js");
const fb = require("../firebaseConfig.js");
const dbReservations = require("../db-reservations.js");
const logger = require("../fb-logger.js");

import { mapState } from "vuex";

import ReservationCalendar from "@/components/reservationCalendar";
import DayOverview from "@/components/dayOverview";


export default {
  name: "calendarPage",
  components: {
    ReservationCalendar,
    DayOverview
  },
  data: function () {
    return {
      selectedDate: null
    };
  },
  methods: {
    selectDate(d){
      // console.log("[calendar] Date selected:"+d)
      this.selectedDate=d
    }
  },
  mounted() {
  },
  filters: helpers.filters,
};
</script>

