var firebaseConfig = {
    apiKey: "AIzaSyByg4x5a9TKai3Cc8en1gfZ8fbyJEHNIgQ",
    authDomain: "lkpanenka-fc7ac.firebaseapp.com",
    databaseURL: "https://lkpanenka-fc7ac.firebaseio.com",
    projectId: "lkpanenka-fc7ac",
    storageBucket: "lkpanenka-fc7ac.appspot.com",
    messagingSenderId: "209229783706",
    appId: "1:209229783706:web:310efd4c9ff2e2225c7523"
  };
 
  export default firebaseConfig;