import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'


// firebase init goes here
import config from "./config"

//console.log(config)

firebase.initializeApp(config)

// firebase utils
const db = firebase.firestore()
const auth = firebase.auth()
const currentUser = auth.currentUser

// date issue fix according to firebase

// firebase collections
const messagesCollection = db.collection('messages')
const usersCollection = db.collection('users')
const membersCollection = db.collection('members')
const friendsCollection = db.collection('friends')
const reservationCollection = db.collection('reservations')
const eventsCollection = db.collection('events')
const userActions = db.collection('userActions')

const urlPrefix = "https://us-central1-lkpanenka-fc7ac.cloudfunctions.net"

function translateAuthError(err) {
    if (err.code == "auth/wrong-password") {
        return "Chybné heslo"
    } else if (err.code == "auth/user-not-found") {
        return "Tohle uživatele neznám"
    } else if (err.code == "auth/invalid-email") {
        return "Přihlašovací jméno musí být email"
    } else if (err.code == "auth/weak-password") {
        return "Slabé heslo. Prosím alespoň 6 znaků"
    } else if (err.code == "auth/email-already-in-use") {
        return "Tento email už někdo používá. To je divné. Zadej jiný a nebo kontaktuj Jirku."
    } else {
        return err.message
    }
}

function getCurrentUser(){
    return firebase.auth().currentUser
}

export {
    urlPrefix,
    db,
    auth,
    currentUser,
    getCurrentUser,
    messagesCollection,
    friendsCollection,
    usersCollection,
    membersCollection,
    reservationCollection,
    eventsCollection,
    translateAuthError,
    userActions
}