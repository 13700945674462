var axios = require('axios')

const url = "https://us-central1-lkpanenka-fc7ac.cloudfunctions.net/log"
//const url =  "http://localhost:5001/lkpanenka-fc7ac/us-central1/log"

if (document.location.hostname == 'localhost') {
    MODE = "DEV"
} else {
    MODE = "PROD"
}

console.log("[log] started in mode: " + MODE)

module.exports = {
    log: log,
    info: log,
    warning: warning,
    error: error,
    debug: debug
}

function error(pl) {
    doLog("ERROR", pl)
}

function warning(...args) {
    doLog("WARNING", args)
}

function log(...args) {
    doLog("INFO", ...args)
}

function debug(...args) {
    if (MODE == "DEV") doLog("DEBUG", ...args)
}

function doLog(level, ...args) {
    let data = {}
    data.level = level
    let pl = ""
    args.forEach(a => {
        if (a instanceof Object) pl += JSON.stringify(a) + " | "
        else pl += a + " | "
    });
    data.log = pl.trim()


    if (MODE === "DEV") {
        console.log("[logger] " + JSON.stringify(data))
    } else {
        //send to rollbar
        Rollbar.configure({logLevel: level.toLowerCase()})
        Rollbar.log(data.log);
        //send to firebase logger
        axios.post(url, data).then((response) => {
                //console.log("[log] ok")
            })
            .catch((err) => {
                console.error("[log] error" + err)
            })
    }
}