<template id="memberPage">
  <div>
    <v-row>
      <v-col sm="4"><span class="text-h3">Seznam členů</span></v-col>
      <v-col sm="2">
        <v-btn
          @click="copyEmails()"
          :disabled="emailsCopied"
          color="red"
          v-if="!userLoading && userProfile.roles.manager"
        >
          <v-icon dark>mdi-email</v-icon>&nbsp;
          <span v-if="!emailsCopied">Emaily</span>
          <span v-else>zkopírováno</span>
          &nbsp;<v-icon>mdi-lock</v-icon>
        </v-btn>
      </v-col>
      <v-col sm="2">
        <v-btn
          @click="copyPhones()"
          :disabled="phonesCopied"
          color="red"
          v-if="!userLoading && userProfile.roles.manager"
        >
          <v-icon dark>mdi-phone</v-icon>&nbsp;
          <span v-if="!phonesCopied">Telefony</span>
          <span v-else>zkopírováno</span>
          &nbsp;<v-icon>mdi-lock</v-icon>
        </v-btn>
      </v-col>
      <v-col sm="4">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Hledej.."
          single-line
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12">
        <v-data-table
          :headers="headers"
          :items="members"
          hide-actions
          :item-class="itemRowBackground"
          class="elevation-1"
          items-per-page="200"
          :search="search"
          :loading="loading"
        >
          <template v-slot:item.phones="{ item }">
            {{ item.phones.join(", ") }}
          </template>

          <template v-slot:item.roles="{ item }">
            <span v-if="item.child">dítě</span>
            <span v-if="item.roles.admin"> [admin]</span>
            <span v-if="item.roles.manager"> [správce]</span>
            <span v-if="userProfile.roles.manager">
              <span v-if="item.firebaseUserId"> (používá web)</span>
              <span v-else></span>
            </span>
          </template>

          <template slot="items" slot-scope="props">
            <td>{{ props.item.name.first }}</td>
            <td>{{ props.item.name.last }} {{ props.item.name.apendix }}</td>
            <td>{{ props.item.email }}</td>
            <td>{{ props.item.phones.join(", ") }}</td>
            <td>
              <span v-if="props.item.child">dítě</span>
              <span v-if="props.item.roles.admin">admin</span>
              <span v-if="props.item.roles.manager">správce</span>
              <span v-if="userProfile.roles.manager">
                <span v-if="props.item.firebaseUserId">(používá web)</span>
                <span v-else></span>
              </span>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script type="text/javascript">
const fb = require("../firebaseConfig.js");
import { mapState } from "vuex";

export default {
  name: "memberPage",
  computed: {
    ...mapState(["currentUser", "userProfile", "userLoading"]),
  },
  data() {
    return {
      loading: false,
      search:"",
      emailsCopied: false,
      phonesCopied: false,
      members: [],
      headers: [
        { text: "Jméno", value: "name.first" },
        { text: "Příjmení", value: "name.last" },
        { text: "Email", value: "email" },
        { text: "Telefon", value: "phones" },

        { text: "Role", value: "roles" },
      ],
    };
  },
  methods: {
    itemRowBackground: function (item) {
      return item.status == "ACTIVE" ? "style-green" : "style-red";
    },
    copyEmails() {
      var emails = this.members
        .map((item) => item.email)
        .filter((item) => item.length > 0)
        .join("\n");
      copyStringToClipboard(emails);
      this.emailsCopied = true;
    },
    copyPhones() {
      var phones = this.members
        .map((item) => {
          for (var i = 0; i < item.phones.length; i++) {
            if (item.phones[i][0] != "3") return item.phones[i];
          }
          return "";
        })
        .filter((item) => item.length > 0)
        .join("\n");
      copyStringToClipboard(phones);
      this.phonesCopied = true;
    },
  },
  mounted() {
    this.loading=true
    
    fb.membersCollection.where("active","==",true).get().then((snapshot) => {
      this.members = [];
      snapshot.forEach((doc) => {
        this.members.push(doc.data());
      });
      this.loading=false;
    });
  },
};

function copyStringToClipboard(str) {
  var el = document.createElement("textarea");
  el.value = str;
  el.setAttribute("readonly", "");
  el.style = { position: "absolute", left: "-9999px" };
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
}
</script>

<style scoped>
.style-green {
  background-color: "#00ff00aa";
}
.style-red {
  background-color: "#ff0000aa";
}
</style>
