<template id="reservationCalendar">
  <v-container>
    <v-row>
      <v-col md="6" sm="6">
        <v-btn @click="$refs.calendar.prev()" class="">
          <v-icon dark left> mdi-chevron-left </v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">Předchozí</span>
        </v-btn>

        <v-btn @click="$refs.calendar.next()" class="ml-2">
          <span v-if="$vuetify.breakpoint.smAndUp">Další</span>
          <v-icon right dark>mdi-chevron-right</v-icon>
        </v-btn>

        <v-btn
          class="ml-10"
          v-if="$vuetify.breakpoint.mdAndUp"
          @click="setToday()"
        >
          DNES
        </v-btn>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.smAndUp"></v-col>
      <v-col md="2" sm="4">
        <v-card class="pa-2 text-h5" outlined>
          <span v-if="calendarLoading"><loader size="30" color="#00f" /></span>
          {{ calendarDateStrMonth }}
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-sheet height="600">
          <v-calendar
            
            ref="calendar"
            type="month"
            color="primary"
            locale="cs"
            
            :events="events"
            event-overlap-mode="column"
            :event-overlap-threshold="30"
            :event-color="getEventColor"
            :event-height="30"
            event-text-color="black"
            :weekdays="weekdays"
            :event-more="false"

            
            v-model="calendarDateStr"
            
            @click:date="selectDate($event.date)"
            @click:day="selectDate($event.date)"
            @click:event="selectDate($event.day.date)"
            @change="reloadReservations($event)"
            
            v-touch="{
              left: () => $refs.calendar.next(),
              right: () => $refs.calendar.prev(),
            }"
          >
          <template v-slot:event={event}>
            
             <span class="pa-2 text-body-1" > {{event.name}} </span> 
            
           </template>


          </v-calendar>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const helpers = require("../helpers.js");
const fb = require("../firebaseConfig.js");
const dbReservations = require("../db-reservations.js");
const logger = require("../fb-logger.js");

import { mapState } from "vuex";

import reservationForm from "@/components/reservationForm";

import { CalendarView, CalendarViewHeader } from "vue-simple-calendar";
require("@/assets/css/calendar.css");
const moment = require("moment");
const colormap = [
  "#2ea829",
  "#519c07  ",
  "#659000",
  "#738300",
  "#7d7600",
  "#846a00",
  "#875d00",
  "#895000",
  "#874407",
  "#843914",
  "#7e2f1c",
];

export default {
  name: "calendarPage",
  model: {
    event: "dateSelected",
  },
  components: {
    CalendarView,
    //CalendarViewHeader,
    reservationForm,
  },
  data: function () {
    return {
      weekdays: [1, 2, 3, 4, 5, 6, 0],
      calendarDateStr: moment(new Date()).format("YYYY-MM-DD"),
      showDate: new Date(),
      selectedDate: null,
    };
  },
  computed: {
    ...mapState([
      "reservationsPerDay",
      "selectedMonth",
      "actionEvents",
      "calendarLoading",
      "userProfile",
    ]),
    events() {
      let out=[]
      

      for(let d in this.reservationsPerDay){
        let day=this.reservationsPerDay[d]
        out.push({
          allDay: true,
          start: new Date(d),
          end: new Date(d),
          name: day.total+" / "+day.capacity,
          fullness: day.total / day.capacity * 100,
          color : colormap[Math.ceil((day.total / day.capacity) * 10)],
          index: 10,
        })
      }

      //
      this.actionEvents.forEach((e) => {
        out.push({
          allDay: true,
          start: e.startDate,
          end: e.endDate,
          name: e.title,
          color: "#aaaaff",
          index: 1
        });
      });
      return out
    },
    calendarDateStrMonth() {
      return moment(this.calendarDateStr).format("MM / YYYY");
    },
    calendarDate() {
      return moment(calendarDateStr).toDate();
    },
    //----
    selectedDateIsInFuture() {
      return moment(this.selectedDate).isSameOrAfter(
        moment().subtract(1, "day")
      );
    },
  },
  watch: {
    calendarDateStr: function (val) {
      // console.log(val)
      // this.setShowDate(moment(val).toDate());
    },

    reservationsPerDay: function () {
      if (this.selectedDate) {
        this.selectDate(this.selectedDate);
      }
    },
  },

  methods: {
    getEventColor(event){
      return event.start.getTime()< (new Date()).getTime()-24*3600*1000?"#999999": event.color
    },
    reloadReservations(event){
        let actualMonth=this.selectedMonth.getMonth()+1
        let startMonth=event.start.month
        let endMonth=event.end.month
        // console.log("Checking month "+actualMonth+" vs. "+startMonth +"<>"+endMonth)
        if(actualMonth != startMonth){
                this.$store.commit("setSelectedMonth", new Date(startMonth=event.start.date));
                this.$store.dispatch("fetchReservationsForMonth");
        }
    },
    setToday() {
      this.setShowDate(new Date());
      this.calendarDateStr = this.dateToStr(new Date());
    },
    selectDate(d) {
      // console.log("[calendarComponent] selectDate:"+JSON.stringify(d))
      this.selectedDate = d;
      this.$emit("dateSelected:date", moment(d).format("YYYY-MM-DD"));
    },
    setShowDate(d) {
      this.$store.commit("setSelectedMonth", d);
      this.$store.dispatch("fetchReservationsForMonth");
      this.showDate = d;
      this.selectedDate = null;
    },

    getDayContent(day) {
      let dayStr = this.dateToStr(day);
      let dayData = this.reservationsPerDay[dayStr];

      if (dayData) {
        let fullness = Math.floor((dayData.total / dayData.capacity) * 100);
        let color =
          colormap[Math.ceil((dayData.total / dayData.capacity) * 10)];
        return {
          ...dayData,
          fullness,
          color,
        };
      }
    },
    dateToStr(date) {
      return moment(date).format("YYYY-MM-DD");
    },
  },
  mounted() {
    this.$store.dispatch("fetchReservationsForMonth");

    let height = "4em";
    if (this.$vuetify.breakpoint.xs) height = "4em";
    if (this.$vuetify.breakpoint.mdAndUp) height = "4em";

    var sheet = document.createElement("style");
    sheet.innerHTML = ".cv-week {min-height: " + height + "; }";
    document.body.appendChild(sheet);
  },
  filters: helpers.filters,
};
</script>

<style>
/* .cv-week{
    min-height: 5em;
} */

.cv-day-number {
  visibility: hidden;
}

.cv-event {
  background-color: #4444ff;
}

.lkDay {
  padding: 5px;
  width: 100%;
}

.percentbar {
  background: #cccccc;
  border: 1px solid #666666;
  height: 10px;
}
.percentbar div {
  background: blue;
  height: 10px;
}

.dayTable {
  border: 1px solid gray;
  border-collapse: collapse;
}

.dayTable td {
  border: 1px solid gray;
  border-collapse: collapse;
  padding: 5px 5px;
}

.dayTable th {
  padding: 5px 5px;
  border: 1px solid gray;
  border-collapse: collapse;
}

tr.rowGreen {
  background-color: #aaffaa;
}

tr.rowRed {
  background-color: #ffaaaa;
}
</style>
