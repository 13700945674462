<template id="loginPage">
  <v-container fluid grid-list-md>
    <v-layout row wrap>
      <v-flex d-flex xs12 sm12 md6>
        <v-layout row wrap>
          <v-flex d-flex>
            <!-- karta loginu -->
            <v-card class="elevation-12">
              <v-form v-model="valid" lazy-validation>
              <v-toolbar dark color="amber">
                <v-toolbar-title>Přihlášení na Panenku</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
              <transition name="fade">
                <v-alert v-if="errorMessage" :value="true" type="error">
                  {{errorMessage}}
                </v-alert>
              </transition>
              <v-card-text>
                <p v-if="forgotPassWordMode">Zadejte email, který používáte k přihlášení. Pokud bude správný, obdržíte
                  na něj link k akivaci hesla.</p>
                <v-alert type="success" :value=true v-if="passwordResetSuccess">
                  Heslo bylo zasláno na email.
                </v-alert>
                  <v-text-field v-model.trim="loginForm.email" prepend-icon="mdi-account" name="login" label="Přihlašovací email"
                    type="text" required :rules="emailRules" @change="checkLoginInput"></v-text-field>
                  <v-text-field v-model.trim="loginForm.password" id="password" prepend-icon="mdi-lock" name="password"
                    label="Heslo" type="password" v-if="!forgotPassWordMode" @keyup.enter.native="login" required :rules="notEmptyRules"></v-text-field>
                  <v-checkbox v-model="loginForm.rememberMe"
                    label="Zapamatuj si mně na tomto počítači a přihlašuj mě automaticky." />
              </v-card-text>
              <v-card-actions v-if="!forgotPassWordMode">
                <v-btn color="secondary" @click="toggleForgotPasswordMode">Zapomenuté heslo</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="login" :disabled="(!valid)||(loginForm.email.length<=1)">Přihlášení</v-btn>
              </v-card-actions>
              <v-card-actions v-else>
                <v-btn color="secondary" @click="toggleForgotPasswordMode">Zpět na login</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="forgotPassword">Zaslat heslo</v-btn>
              </v-card-actions>
              </v-form>
            </v-card>
          </v-flex>

        </v-layout>
      </v-flex>
      <v-flex xs12 md6 sm12>
        <message-list visibilityMode="public" :count="1" />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import messageList from '@/components/messageList'
  const fb = require('../firebaseConfig.js')
  import { mapState } from 'vuex'
  const logger=require('../fb-logger.js')


  export default {
    name: 'loginPage',
    components: {
      messageList
    },
    data() {
      return {
        numOldLoginTries: 0,
        showActivationCard: true,
        forgotPassWordMode: false,  
        passwordResetSuccess: false,
        errorMessage: '',
        valid: false,
        loafing: false,
        loginForm: {
          rememberMe: true,
          email: '',
          password: ''
        },
        

        notEmptyRules:[
            v => !!v && v.length > 0 || 'Musí být vyplněno' 
        ],
        emailRules: [
            v => !!v || 'Musí být vyplněno',
            v => /.+@.+/.test(v) || 'Musí být platný email pro nový účet. Aktivace dole.'
        ]

      }
    },
    computed:{
      ...mapState(['currentUser', 'userProfile', 'userLoading']),
    },
    methods: {
      checkLoginInput(){
        if((this.loginForm.email.indexOf("@")==-1) && this.loginForm.email.match(/^[\w]*\.[\w]*/)){
          console.log("Attempt old login")
          this.numOldLoginTries++;
          if(this.numOldLoginTries==2){
            this.errorMessage="Zkoušíš se přihlásit starým loginem. Použij email, který si nejdříve aktivuješ. Aktivace je tačítko dole."
          }
        }
      },
      toggleForgotPasswordMode() {
        this.forgotPassWordMode = !this.forgotPassWordMode
      },
      signUp() {
        this.$router.push('/signup')
      },
      forgotPassword() {
        this.loading = true;
        fb.auth.sendPasswordResetEmail(this.loginForm.email).then(() => {
          this.loading = false
          this.passwordResetSuccess = true
          this.toggleForgotPasswordMode()
          this.errorMessage=''
          this.loginForm.email = ''
        }).catch(err => {
          logger.error("Forgot password error",err)
          this.loading = false
          this.errorMessage = fb.translateAuthError(err)
        })
      },
      login() {
        let persistence = 'local';
        if(!this.loginForm.rememberMe) {
          persistence = 'session';
        }
        logger.info("[LoginForm] login",this.loginForm.email," with persistence: ",persistence)
        var lf=this.  loginForm
        fb.auth.setPersistence(persistence)
        .then(()=>{
                  fb.auth.signInWithEmailAndPassword(lf.email, lf.password).then(user => {
                    logger.info("[LoginForm] login ok:", lf.email)
                    setTimeout(() => {
                      this.user = user
                      this.$router.push("/")
                    }, 100)
                  }).catch(err => {
                    logger.warning("[LoginForm] login failed for:",lf.email, err)
                    this.errorMessage = fb.translateAuthError(err)                    
                  })
        })
      }
    },
    mounted() {
      setTimeout(() => {
          if(this.currentUser){
            this.$router.push("/home")
          }
        }, 200)
    }
  }
</script>