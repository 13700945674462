<template>
  <div>
    <v-card sm="12" class="ma-4">
      <!-- admin mode --->
      <v-toolbar color="red" dark v-if="adminMode">
        <v-toolbar-title>
          <span v-if="mode == 'ADD'">Nová rezervace pro jiného člena</span>
          <span v-else>Úprava rezervace</span>
          &nbsp;<v-icon>mdi-lock</v-icon>
        </v-toolbar-title>
        <v-spacer />
        <v-btn color="cancel" @click="cancel()">Zrušit</v-btn>
        <v-btn
          class="ml-10"
          :disabled="!valid || !dataOk"
          color="primary"
          @click="saveReservation"
          >Uložit</v-btn
        >
      </v-toolbar>
      <!-- /admin mode --->

      <v-toolbar color="blue" dark v-else>
        <v-toolbar-title>
          <span v-if="mode == 'ADD'">Nová rezervace</span>
          <span v-else>Úprava rezervace</span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn color="cancel" @click="cancel()">Zrušit</v-btn>
        <v-btn
          class="ml-10"
          :disabled="!valid || !dataOk"
          color="primary"
          @click="saveReservation"
          >Uložit</v-btn
        >
      </v-toolbar>

      <!-- od - do -->
      <v-form v-model="valid" lazy-validation>
        <v-row>
          <v-col sm="5">
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateFromFormatted"
                  label="Datum příjezdu"
                  hint="např. 15.3.2018"
                  persistent-hint
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  @blur="date = parseDate(dateFromFormatted)"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateFrom"
                no-title
                @input="menu1 = false"
                locale="cs-cz"
                first-day-of-week="1"
                :allowed-dates="allowedDatesFrom"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col sm="2">
            <v-text-field
              v-model="nights"
              :counter="2"
              label="Počet nocí"
              :rules="numberRule"
              required
            ></v-text-field>
          </v-col>
          <v-col sm="5">
            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateToFormatted"
                  label="Datum odjezdu"
                  hint="např. 15.3.2018"
                  persistent-hint
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  @blur="date = parseDate(dateToFormatted)"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateTo"
                no-title
                @input="menu2 = false"
                locale="cs-cz"
                first-day-of-week="1"
                :allowed-dates="allowedDatesTo"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <!-- / od -do -->
        <v-row>
          <v-col v-if="adminMode">
            <v-card>
              <v-card-text>
                <h3>
                  Přihlášení členové
                  <span v-if="userLoading">
                    <loader size="25" colot="white"></loader>
                  </span>
                </h3>
              </v-card-text>
              <v-list :dense="true">
                <v-list-item
                  v-for="(member, index) in subscribedMembers"
                  :key="index"
                >
                  <v-btn icon v-if="member.id == reservedBy"
                    ><v-icon></v-icon
                  ></v-btn>
                  <v-btn icon v-else @click="admin_setAsAuthor(member.id)"
                    ><v-icon>mdi-radiobox-blank</v-icon></v-btn
                  >
                  {{ member.name.print }}

                  <v-list-item-action>
                    <v-btn
                      icon
                      color="red"
                      @click="removeMemberFromReservation(member)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>

                <v-list-item>
                  <v-list-item-action>
                    <v-tooltip bottom>
                      <v-btn slot="activator" icon color="green">
                        <v-icon>mdi-star</v-icon>
                      </v-btn>
                      <span>Vyhledej člena a dej Enter</span>
                    </v-tooltip>
                  </v-list-item-action>
                  <v-autocomplete
                    v-model="admin_newMemberToAdd"
                    no-data-text="Nikdo nenalezen ;-("
                    :hint="
                      !isEditing1 ? 'Klinkni pro výběr' : 'Enter pro uložení'
                    "
                    :items="allMembersNames"
                    item-text="memberText"
                    item-value="memberId"
                    :readonly="!isEditing1"
                    label="Přidej člena, do rezervace"
                    persistent-hint
                    prepend-icon="mdi-account"
                    @keyup.enter.native="admin_addMemberToReservation"
                    @change="admin_addMemberToReservation"
                  >
                  </v-autocomplete>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
          <!-- /admin -->

          <v-col v-else>
            <v-card>
              <v-card-text>
                <h3>
                  Členové, které můžeš zapsat
                  <span v-if="userLoading">
                    <loader size="25" colot="white"></loader>
                  </span>
                </h3>
              </v-card-text>
              <v-list :dense="true">
                <v-list-item
                  v-for="(member, index) in allMembersYouCanSubscribe"
                  :key="index"
                >
                  <v-list-item-action>
                    <v-btn
                      v-if="!isSubscribedMember(member)"
                      icon
                      color="red"
                      @click="addMemberToReservation(member)"
                    >
                      <v-icon>mdi-checkbox-blank-outline</v-icon>
                    </v-btn>
                    <v-btn
                      v-else
                      icon
                      color="green"
                      @click="removeMemberFromReservation(member)"
                    >
                      <v-icon>mdi-checkbox-marked-outline</v-icon>
                    </v-btn>
                  </v-list-item-action>

                  {{ member.name.print }}
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>

          <!-- kamaradi -->
          <v-col v-if="adminMode">
            <v-card>
              <v-card-text>
                <h3>
                  Přihlášení kamarádi
                  <span v-if="userLoading">
                    <loader size="25" colot="white"></loader>
                  </span>
                </h3>
              </v-card-text>
              <v-list :dense="true">
                <v-list-item
                  v-for="(friend, index) in subscribedFriends"
                  :key="index"
                >
                  <v-btn
                    
                    icon
                    color="red"
                    @click="removeFriendFromReservation(friend)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>

                  {{ friend.name.print }}
                  <v-list-item-action> </v-list-item-action>
                </v-list-item>
                <v-list-item>
                  <v-list-item-action>
                    <v-tooltip bottom>
                      <v-btn slot="activator" icon color="green">
                        <v-icon>mdi-star</v-icon>
                      </v-btn>
                      <span>Vyhledej kamaráda a dej Enter</span>
                    </v-tooltip>
                  </v-list-item-action>

                  <v-autocomplete
                    v-model="admin_newFriendToAdd"
                    no-data-text="Nikdo nenalezen ;-("
                    :hint="
                      !isEditing2 ? 'Klinkni pro výběr' : 'Enter pro uložení'
                    "
                    :items="allFriendNames"
                    item-text="memberText"
                    item-value="memberId"
                    :readonly="!isEditing2"
                    label="Přidej kamaráda, do rezervace"
                    persistent-hint
                    prepend-icon="mdi-account"
                    @keyup.enter.native="admin_addFriendToReservation"
                    @change="admin_addFriendToReservation"
                  >
                  </v-autocomplete>
                </v-list-item>
              </v-list>
              <v-divider />
              <friend-form
                v-if="!userLoading"
                mode="ADD"
                v-on:friend-added="handleAddedFriend($event)"
              />
            </v-card>
          </v-col>

          <v-col v-else>
            <v-card>
              <v-card-text>
                <h3>
                  Kamarádi, které můžeš přihlásit
                  <span v-if="userLoading">
                    <loader size="25" colot="white"></loader>
                  </span>
                </h3>
              </v-card-text>
              <v-list :dense="true">
                <v-list-item
                  v-for="(friend, index) in allFriendsYouCanSubscribe"
                  :key="index"
                >
                  <v-list-item-action>
                    <v-btn
                      v-if="!isSubscribedFriend(friend)"
                      
                      icon
                      color="red"
                      @click="addFriendToReservation(friend)"
                    >
                      <v-icon>mdi-checkbox-blank-outline</v-icon>
                    </v-btn>
                    <v-btn
                      v-else
                      
                      icon
                      color="green"
                      @click="removeFriendFromReservation(friend)"
                    >
                      <v-icon>mdi-checkbox-marked-outline</v-icon>
                    </v-btn>
                  </v-list-item-action>
                  {{ friend.name.print }}
                </v-list-item>
              </v-list>
              <v-divider />
              <friend-form
                v-if="!userLoading"
                mode="ADD"
                v-on:friend-added="handleAddedFriend($event)"
              />
            </v-card>
          </v-col>
        </v-row>
        <!-- poznamka -->
        <v-row>
          <v-col>
            <v-text-field v-model="note" label="poznámka" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-alert v-if="message" type="success" :value="true">
              {{ message }}
            </v-alert>
            <v-alert v-if="errorMessage" type="error" :value="true">
              <strong>Rezervaci nelze uložit: </strong> {{ errorMessage }}
              <span v-if="duplicatedMembers"
                ><br /><span v-for="member in duplicatedMembers"
                  >{{ member.name.first }} {{ member.name.last }},
                </span></span
              >
            </v-alert>
            <v-alert v-if="checkClubRules" type="warning" :value="true">
              <strong
                >Pozor. Rezervaci půjde uložit, ale nemusí být schválena</strong
              ><br />
              {{ checkClubRules }}
            </v-alert>
          </v-col>
          <v-col>
            <v-btn
              :disabled="!valid || !dataOk"
              color="primary"
              @click="saveReservation"
              >Uložit rezervaci jako: {{ reservedByName }}</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>

<script>
const moment = require("moment");
const fb = require("../firebaseConfig.js");
const getReservationsForDateRange = require("../store.js")
  .getReservationsForDateRange;
const dbPersons = require("../db-persons.js");
const dbReservations = require("../db-reservations.js");
const logger = require("../fb-logger.js");

import { mapState } from "vuex";
import friendForm from "@/components/friendForm";

export default {
  props: {
    dateFromProp: {
      type: Date,
      default: null,
    },
    mode: {
      type: String,
      default: "ADD", //EDIT
    },
    reservation: {
      type: Object,
      default: null,
    },
    returnPage: {
      type: String,
      default: "/reservations",
    },
    admin: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    "friend-form": friendForm,
  },
  data: () => ({
    valid: false,
    errorMessage: "",
    message: "",
    duplicatedMembers: [],

    //reservtion data for editating
    dateFrom: null,
    dateFromFormatted: null,
    dateTo: null,
    dateToFormatted: null,
    nights: 2,
    menu1: false,
    menu2: false,
    subscribedFriends: [],
    subscribedMembers: [],
    note: "",
    reservedBy: null,
    reservedByName: null,

    //pro admin mode
    allMembers: [],
    allMembersNames: [],
    allFriends: [],
    allFriendNames: [],

    isEditing1: true,
    admin_newMemberToAdd: null,

    isEditing2: true,
    admin_newFriendToAdd: null,

    //form validation
    emailRules: [
      (v) => !!v || "Musí být vyplněné",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    notEmpty: [(v) => !!v || "Musí být vyplněné"],
    numberRule: [
      (v) => !!v || "Číslo musí být vyplněné",
      (v) => /^[\d]*$/.test(v) || "Zadejte číslo",
    ],
    dateRule: [(v) => !!v || "Datum musí být vyplněné"],
  }),
  mounted() {
    logger.info(
      "[RESERVATION-FORM] Mounted ",
      this.mode,
      this.admin,
      this.reservation
    );
    if (this.mode == "ADD") {
      this.dateFrom = moment(this.dateFromProp).format("YYYY-MM-DD");
      this.dateTo = moment(this.dateFromProp)
        .add(2, "day")
        .format("YYYY-MM-DD");
      this.nights = 2;
      try {
        this.reservedBy = this.userProfile.id;
        this.reservedByName = this.userProfile.name.print;
      } catch (e) {
        this.$log.error("failed reading reserved by");
      }
    } else if (this.mode == "EDIT") {
      this.extractDataFromReservation();
    }
    this.getAllMembers();
    this.getAllFriends();
  },
  computed: {
    ...mapState(["currentUser", "userProfile", "userLoading"]),
    dataOk() {
      return this.subscribedFriends.length + this.subscribedMembers.length > 0;
    },
    adminMode() {
      this.$log.debug("Checking admin mode", this.userProfile);
      if (this.userProfile.roles == undefined) return false;
      return this.userProfile.roles.manager && this.admin;
    },

    allMembersYouCanSubscribe() {
      let out = [this.userProfile];
      if (!this.userProfile || !this.userProfile.canSubscribeExpanded)
        return [];
      return out.concat(this.userProfile.canSubscribeExpanded);
    },
    allFriendsYouCanSubscribe() {
      if (!this.userProfile || !this.userProfile.friendsExpanded) return [];
      return this.userProfile.friendsExpanded;
    },
    //kontrola dva cleni apod
    checkClubRules() {
      if (
        this.subscribedMembers.length == 0 &&
        this.subscribedFriends.length != 0
      ) {
        return "Rezervace porušuje pravidla klubu. Kamarádi nemohou jet na chatu sami. Dohodni se s vedoucím zájezdu.";
      } else if (
        this.subscribedMembers.length * 2 <
        this.subscribedFriends.length
      ) {
        return "Rezervace porušuje pravidla klubu. Max. 2 kamarádi na jednoho člena.";
      }
      return null;
    },
  },

  watch: {
    userProfile(val) {
      if (this.reservedBy == null) {
        this.reservedBy = this.userProfile.id;
        this.reservedByName = this.userProfile.name.print;
      }
    },
    reservation(val) {
      this.extractDataFromReservation();
    },
    dateFromProp(val) {
      this.$log.debug(val);
      this.dateFrom = moment(val).format("YYYY-MM-DD");
    },
    dateFrom(val) {
      this.$log.debug(val);
      this.dateFromFormatted = this.formatDate(this.dateFrom);
      if (!this.dateTo || moment(this.dateTo).isBefore(this.dateFrom)) {
        this.dateTo = this.dateFrom;
      }
      this.dateTo = moment(this.dateFrom)
        .add(this.nights, "day")
        .format("YYYY-MM-DD");
    },
    dateTo(val) {
      this.$log.debug(val);
      this.dateToFormatted = this.formatDate(this.dateTo);
      this.updateNights();
    },
    nights(val) {
      this.$log.debug(val);
      this.dateTo = moment(this.dateFrom)
        .add(this.nights, "days")
        .format("YYYY-MM-DD");
    },
  },

  methods: {
    admin_setAsAuthor(memberId) {
      this.reservedBy = memberId;
      this.reservedByName = this.allMembers[memberId].name.print;
    },
    getAllMembers() {
      dbPersons.getAllMembers().then((members) => {
        this.allMembers = dbPersons.prepareAllMembersById(members);
        this.allMembersNames = dbPersons.prepareMemberNamesList(members);
      });
    },
    getAllFriends() {
      dbPersons.getAllFriends().then((friends) => {
        this.allFriends = dbPersons.prepareAllMembersById(friends);
        this.allFriendNames = dbPersons.prepareMemberNamesList(friends);
      });
    },
    handleAddedFriend(friend) {
      this.$log.info("Handling new friend", friend);
      this.addFriendToReservation(friend);
      this.getAllFriends();
    },

    admin_addMemberToReservation() {
      this.$log.debug("Adding new member", this.admin_newMemberToAdd);
      if (this.admin_newMemberToAdd == null) return;

      this.addMemberToReservation(this.allMembers[this.admin_newMemberToAdd]);
      this.admin_newMemberToAdd = null;
    },
    admin_addFriendToReservation() {
      this.$log.debug("Adding new friend", this.admin_newFriendToAdd);
      if (this.admin_newFriendToAdd == null) return;

      this.addFriendToReservation(this.allFriends[this.admin_newFriendToAdd]);
      this.admin_newFriendToAdd = null;
    },

    extractDataFromReservation() {
      this.$log.debug(
        "extracting data from reservation to component",
        this.reservation
      );

      this.dateFrom = moment(this.reservation.from.toDate()).format(
        "YYYY-MM-DD"
      );
      this.dateTo = moment(this.reservation.to.toDate()).format("YYYY-MM-DD");
      this.updateNights();
      this.note = this.reservation.note;
      this.reservedBy = this.reservation.reservedBy;
      this.reservedByName =
        this.reservation.reservedByName || this.userProfile.name.print;
      dbPersons
        .expandMembersFromOldId(this.reservation.members)
        .then((mem) => {
          this.subscribedMembers = mem;
          return dbPersons.expandFriendsFromOldId(this.reservation.friends);
        })
        .then((fri) => {
          this.subscribedFriends = fri;
        });
    },
    updateNights() {
      this.$log.debug("update nights");
      this.nights = moment(this.dateTo).diff(moment(this.dateFrom), "days");
    },
    saveReservation() {
      this.message = "";
      this.errorMessage = "";

      //constructReservation
      let reservation = {
        reservedBy: this.reservedBy,
        reservedByName: this.reservedByName,
        createDate: new Date(),
        friends: this.subscribedFriends.map((item) => item.id),
        friendNames: this.subscribedFriends.map((item) => {
          if (item.name) {
            return item.name.print;
          } else {
            return item.id;
          }
        }),
        members: this.subscribedMembers.map((item) => item.id),
        memberNames: this.subscribedMembers.map((item) => item.name.print),
        from: new Date(this.dateFrom + " 18:00:00"),
        to: new Date(this.dateTo + " 10:00:00"),
        nights: this.nights,
        note: this.note,
        leader: [],
        status: "ACTIVE",
        source: "app",
      };

      logger.info("[RESERVATION-FORM] Saving reservation ", reservation);
      this.$log.info("Saving reservation", reservation);

      if (reservation.members.length + reservation.friends.length == 0) {
        this.errorMessage = "Někoho musíte přihlásit!";
        return;
      }

      if (reservation.from.getTime() == 0) {
        this.errorMessage = "Zadejte správně datum příjezdu.";
        return;
      }

      if (reservation.to.getTime() == 0) {
        this.errorMessage = "Zadejte správně datum odjezdu.";
        return;
      }

      //kotrola na duplicitni zapsani clena nebo pritele v danem case
      getReservationsForDateRange(
        moment(this.dateFrom),
        moment(this.dateTo)
      ).then((data) => {
        let reservations=data.reservations
        this.$log.debug(
          "Checking conflict with following reservation" +
            JSON.stringify(reservations)
        );
        this.duplicatedMembers = [];
        if (reservations) {
          console.log(reservations)
          var membersWithReservation = reservations.map((item) => {
            if (this.reservation != null && this.reservation.id == item.id)
              return []; //vyloucime cleny z teto rezervace v mou editace
            return item.members;
          });
          membersWithReservation = [].concat.apply([], membersWithReservation);
          this.$log.debug(
            "membersWithReservation in this time:",
            membersWithReservation
          );

          for (var i = 0; i < this.subscribedMembers.length; i++) {
            if (
              membersWithReservation.indexOf(this.subscribedMembers[i].id) != -1
            ) {
              this.$log.warn("Duplicate member:", this.subscribedMembers[i]);
              this.duplicatedMembers.push(this.subscribedMembers[i]);
            }
          }
        }

        if (this.duplicatedMembers.length == 0) {
          let ref;
          if (this.mode == "ADD") {
            this.$log.info("Adding new reservation");
            logger.info("[RESERVATION-FORM] Add new reservation");
            ref = fb.reservationCollection.doc();
            reservation.id = ref.id;
          } else {
            this.$log.info("Updating reservation ", this.reservation.id);
            logger.info(
              "[RESERVATION-FORM] Updating resrevation: ",
              reservation.id
            );
            ref = fb.reservationCollection.doc(this.reservation.id);
            reservation.id = this.reservation.id;
          }

          ref.set(reservation).then((res) => {
            logger.info("[RESERVATION-FORM] Saved with id ", reservation.id);
            this.duplicatedMembers;
            this.$log.info("Reservation saved");
            this.message = "Rezervace uložena";
            this.$store.dispatch("fetchReservationsForMonth");
            this.$emit("reservation-saved", reservation);

            //redirect to return page
            if (this.returnPage && this.returnPage != "null") {
              setTimeout(() => {
                this.$router.push(this.returnPage);
              }, 300);
            }
          });
        } else {
          logger.info(
            "[RESERVATION-FORM] Not saved, duplicite members ",
            this.duplicatedMembers
          );
          this.errorMessage = "Duplicitní registrace.";
        }
      });
    },
    cancel(){
          if (this.returnPage && this.returnPage != "null") {
              setTimeout(() => {
                this.$router.push(this.returnPage);
              }, 300);
            }
          this.$emit("cancel")
    },

    isSubscribedMember(member) {
      for (var i = 0; i < this.subscribedMembers.length; i++) {
        if (this.subscribedMembers[i].id == member.id) return true;
      }
      return false;
    },

    isSubscribedFriend(friend) {
      for (var i = 0; i < this.subscribedFriends.length; i++) {
        if (this.subscribedFriends[i].id == friend.id) return true;
      }
      return false;
    },

    addMemberToReservation(member) {
      //TODO: check if is registered already in database
      this.subscribedMembers.push(member);
    },
    removeMemberFromReservation(member) {
      this.subscribedMembers = this.subscribedMembers.filter(
        (item) => item.id !== member.id
      );
    },
    addFriendToReservation(friend) {
      //TODO: check if user is already registered
      this.subscribedFriends.push(friend);
    },
    removeFriendFromReservation(friend) {
      this.subscribedFriends = this.subscribedFriends.filter(
        (item) => item.id !== friend.id
      );
    },

    allowedDatesFrom(date) {
      return moment(date).add(1, "day").isSameOrAfter(new Date());
    },
    allowedDatesTo(date) {
      let fromRestriction = true;
      if (this.dateFrom) {
        fromRestriction = moment(date).add(1, "day").isAfter(this.dateFrom);
      }
      return (
        moment(date).add(1, "day").isSameOrAfter(new Date()) && fromRestriction
      );
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split(".");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
};
</script>