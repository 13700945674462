<template>
    <v-container>
        <v-layout row wrap>
            <v-flex dflex>
                <v-card>
                    <v-toolbar color="light-blue" dark>
                        <v-toolbar-side-icon></v-toolbar-side-icon>

                        <v-toolbar-title>Užitečné soubory</v-toolbar-title>
                      
                    </v-toolbar>

                    <v-list two-line subheader >
                        <v-list-item 
                            v-for="item in files" :key="item.title" avatar 
                            :href="'/public/_docs/'+item.filename"
                        >
                            <v-list-item-avatar>
                                <v-icon :class="[item.iconClass]">{{ item.icon1 }}</v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                                <v-list-item-sub-title>{{ item.subtitle }}</v-list-item-sub-title>
                            </v-list-item-content>

                            <v-list-item-action>
                                <v-btn icon >
                                    <v-icon color="grey lighten-1">{{item.icon2}}</v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
  export default {
    data () {
      return {
        files: [
          { 
            icon1: 'mdi-clipboard-text-outline', iconClass: 'blue white--text', title: 'Členské příspěvky a platby (PDF)', 
            subtitle: 'Za kolik se jezdí na chatu, příspěvky', filename:'2023-prispevky-platby.pdf',
            icon2: 'mdi-file-pdf-outline',
          },
          // { 
          //   icon1: 'mdi-cash-100', iconClass: 'blue white--text', title: 'Příspěvky členů 2022/2023 (XLS)', 
          //   subtitle: 'Pokyny k platbě příspěvků 2022/2023', filename:'2023-prispevky_v2.xlsx',
          //   icon2: 'mdi-file-excel-outline',
          // },
          { 
            icon1: 'mdi-calendar-question', iconClass: 'blue white--text', title: 'Termínovka 2022/2023', 
            subtitle: 'Termínovka 2022/2023', filename:'2023-terminovka.xls',
            icon2: 'mdi-file-excel-outline',
          },          
          { 
            icon1: 'mdi-clipboard-text-outline', iconClass: 'blue white--text', title: 'Aktuální informace ke klubu', 
            subtitle: 'Přihlášky, volební řád, GDPR, ...', filename:'2023-lk-panenka-klub.zip',
            icon2: 'mdi-folder-zip-outline',
          }
        ]
      }
    }
  }
</script>