<template id="personalSettingsPage">
    <v-container fluid grid-list-sm>
        <v-layout column v-if="memberData && memberData.name">
            <v-flex>
                <v-card>
                    <v-toolbar color="blue" dark>
                        <v-toolbar-title>
                            Kontatní údaje ({{uid}})
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-list two-line>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-sub-title>
                                    Starý login: {{memberData.login}},
                                    <span v-if="memberData.firebaseUserId">identifikace: {{memberData.firebaseUserId}}</span>
                                    <span v-else>Uživatel nemá aktivované nové přohlášení</span>
                                </v-list-item-sub-title>

                                <div class="text-xs-center">
                                    <v-chip v-if="memberData.roles.member" color="primary" text-color="white">Člen</v-chip>
                                    <v-chip v-else color="gray" text-color="white">Člen</v-chip>
                                    <v-chip v-if="memberData.roles.admin" color="primary" text-color="white">Admin</v-chip>
                                    <v-chip v-else color="gray" text-color="white">Admin</v-chip>
                                    <v-chip v-if="memberData.roles.manager" color="primary" text-color="white">Správce</v-chip>
                                    <v-chip v-else color="gray" text-color="white">Správce</v-chip>
                                </div>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
 
                    <v-form v-model="valid">
                        <v-container>
                            <v-layout row wrap>
                                <v-flex xs12 sm4 ld4>
                                    <v-text-field v-model="memberData.name.first" :counter="20" :rules="nameRules"
                                        label="Jméno" required></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm4 ld4>
                                    <v-text-field xs12 sm6 ld6 v-model="memberData.name.last" :counter="20" :rules="nameRules"
                                        label="Příjmení" required></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm2 ld2>
                                    <v-text-field v-model="memberData.name.apendix" :counter="5" label="Za jménem"
                                        required></v-text-field>
                                </v-flex>
                                <v-flex>
                                    <v-text-field v-model="memberData.email" :rules="emailRules" label="E-mail pro posílání zpráv"
                                        required></v-text-field>
                                </v-flex>
                                <v-flex>
                                    <v-text-field v-model="memberData.id_number" :rules="notEmpty" label="Číslo občanky"
                                        required></v-text-field>
                                </v-flex>
                               <v-flex xs4>
                                    <v-checkbox v-model="memberData.child"  label="Dítě"
                                        required></v-checkbox>
                                </v-flex>
                                
                                <v-layout row wrap>
                                    <v-flex>
                                        <v-list>
                                            <v-list-item v-for="(phone, index) in memberData.phones" :key="index">
                                                <v-list-item-action>
                                                    <v-icon color="blue">mdi-phone</v-icon>
                                                </v-list-item-action>
                                                <v-list-item-action>
                                                    <v-tooltip bottom>
                                                        <v-btn slot="activator" flat icon color="pink" @click="deletePhone(index)">
                                                            <v-icon>mdi-delete</v-icon>
                                                        </v-btn>
                                                        <span>Smazat telefon</span>
                                                    </v-tooltip>
                                                </v-list-item-action>
                                                <v-list-item-content>
                                                    <v-text-field v-model="memberData.phones[index]" label="Telefon"></v-text-field>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-list-item-action>
                                                    <v-icon color="blue">mdi-phone</v-icon>
                                                </v-list-item-action>
                                                <v-list-item-action>
                                                    <v-btn flat icon color="green" @click="addNewPhone">
                                                        <v-icon>mdi-star</v-icon>
                                                    </v-btn>
                                                </v-list-item-action>
                                                <v-list-item-content>
                                                    <v-text-field v-model="newPhone" @keyup.enter.native="addNewPhone"
                                                        label="Přidat telefon"></v-text-field>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>

                                    </v-flex>

                                    <v-flex>
                                        <v-textarea v-model="memberData.address" :rules="notEmpty" label="Adresa"
                                            required></v-textarea>
                                    </v-flex>


                                </v-layout>
                            </v-layout>
                        </v-container>
                        
                    </v-form>
                </v-card>
            </v-flex>
            <v-spacer><br /></v-spacer>

            <!-- group -->
            <!--
            <v-flex d-flex v-if="memberData.group!='NOGROUP'">
                <v-card>
                    <v-toolbar color="blue" dark>
                        <v-toolbar-title>
                            Patříš do skupiny "{{memberData.group}}"
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <p>Členové skupiny se mohou vzájemně zapisovat. Pokud něco nesedí a chceš někoho přidat, či
                            odebrat napiš Jirkovi.</p>
                        <v-chip v-for="member in groupMembers" :key="member.id">
                            {{member.name.first}} {{member.name.last}} {{member.name.apendix}}
                        </v-chip>
                    </v-card-text>
                </v-card>
            </v-flex>
            -->
            <!-- subscribe -->
            <v-flex d-flex>
                <v-layout row wrap>
                    <v-flex xs12 sm6>
                        <v-card>
                            <v-toolbar color="blue" dark>
                                <v-toolbar-title>
                                    Kdo Tě může zapsat 
                                    <span v-if="isSaving"><loader size=25 colot="white"></loader></span>
                                </v-toolbar-title>
                            </v-toolbar>
                            <v-card-text v-if="memberData.group!='NOGROUP'">
                              Těmhle členům jsi dal povolení Tě zapsat.
                            </v-card-text>
                            <v-list>
                                <v-list-item v-for="(member, index) in canBeSubscribedBy" :key="index">
                                    <v-list-item-action>
                                        <v-tooltip bottom>
                                            <v-btn flat icon color="pink" slot="activator" @click="deleteCanBeSubscribedBy(index)">
                                                <v-icon>mdi-delete</v-icon>
                                            </v-btn>
                                            <span>Odstranit člena ze seznamu</span>
                                        </v-tooltip>
                                    </v-list-item-action>
                                    <div>{{member.name.first}} {{member.name.last}}</div>
                                </v-list-item>
                                <v-list-item>
                                    
                                    <v-list-item-action>
                                        <v-tooltip bottom>
                                            <v-btn slot="activator" flat icon color="green">
                                                <v-icon>mdi-star</v-icon>
                                            </v-btn>
                                            <span>Vyhledej člena a dej Enter</span>
                                        </v-tooltip>
                                    </v-list-item-action>
                                    <v-autocomplete v-model="newCanBeSubscribedBy" :hint="!isEditing1 ? 'Klinkni pro výběr' : 'klikni pro uložení'"
                                        :items="allMembersNames" item-text="memberText" item-value="memberId" :readonly="!isEditing1"
                                        label="Přidej člena, který tě může zapsat" persistent-hint prepend-icon="mdi-account"
                                        @keyup.enter.native="addNewCanBeSubscribedBy">
                                    </v-autocomplete>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-flex>
                    <v-flex xs12 sm6>
                        <v-card>
                            <v-toolbar color="blue" dark>
                                <v-toolbar-title>
                                    Koho Ty můžeš zapsat
                                    <span v-if="isSaving"><loader size=25 colot="white"></loader></span>
                                </v-toolbar-title>
                            </v-toolbar>
                            <v-card-text v-if="memberData.group!='NOGROUP'">
                                Ty můžeš zapisovat tyhle členy.
                            </v-card-text>
                            <v-list>

                                <v-list-item v-for="(member, index) in canSubscribe" :key="index">
                                    <v-list-item-action>
                                        <v-tooltip bottom v-if="memberData.canBeSubscribed.indexOf(member.id)==-1">
                                        <v-btn slot="activator"  flat icon color="green"
                                            @click="addCanBeSubscribedBy(member)">
                                            <v-icon>mdi-plus-box</v-icon>
                                        </v-btn>
                                        <span>Přidej toho člena do seznamu <br>"může mne zapsat"</span>
                                        </v-tooltip>
                                    </v-list-item-action>
                                    <div>{{member.name.first}} {{member.name.last}}</div>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-flex>
            <!-- friends -->
            <v-flex d-flex>
                <v-layout row wrap>
                    <v-flex xs4>
                        <v-card>
                            <v-toolbar color="blue" dark>
                                <v-toolbar-title>
                                    Tvoji kamarádi
                                </v-toolbar-title>
                            </v-toolbar>
                            <v-card-text>
                                <p>Klikni pro editaci. </p>
                                <v-chip @click="addNewFriend">
                                    <v-avatar>
                                        <v-icon color="green">mdi-plus-box</v-icon>
                                    </v-avatar>

                                    Nový
                                </v-chip>
                                <v-chip v-for="friend in friends" :key="friend.id" @click="setEditedFriend(friend)">
                                    {{friend.name.print}} 
                                </v-chip>
                            </v-card-text>
                        </v-card>
                    </v-flex>
                    <v-flex xs8>
                        <v-card>
                            <v-toolbar color="blue" dark>
                                <v-toolbar-title>
                                    Editace údajů kamaráda
                                </v-toolbar-title>
                            </v-toolbar>
                            <friend-form v-bind:editedFriend="editedFriend" v-bind:mode="friendEditMode"></friend-form>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-flex>
            <v-flex d-flex>
                <v-card>
                    <v-toolbar color="blue" dark>
                        <v-toolbar-title>
                                    Emaily a souhlas se zpracováním osobních údajů
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-form>
                            <v-layout>
                                
                                <v-flex xs12>
                                    <h3>Jaké chcete dostávat emaily?</h3>
                                     <v-checkbox v-model="memberData.preferences.emails.reservation"  label="Potvrzení rezervace"/>
                                     <v-checkbox v-model="memberData.preferences.emails.news" label="Informace z nástěnky"/>
                                     
                                </v-flex>
                            </v-layout>
                            <v-divider/>
                            <v-layout xs12>
                                <v-flex xs6>
                                    <v-radio-group @click="updateGdpr" v-model="memberData.preferences.gdprConsent.state" label="Udělujete souhlas se zpracováním osobních údajů klubu">
                                        <v-radio value="APPROVED" label="Souhlasím"></v-radio>
                                        <v-radio value="REJECTED" label="Neusouhlasím"></v-radio>
                                    </v-radio-group>

                                </v-flex>
                                <v-flex xs6>
                                    <v-list>
                                        <v-list-item>
                                            <p v-if="memberData.preferences.gdprConsent.state=='APPROVED'">
                                                Souhlas jsi <strong>udělil</strong> {{memberData.preferences.gdprConsent.date | formatCZDateNoHtml}} na 
                                                <span v-if="memberData.preferences.gdprConsent.form='app'">webu</span>
                                            </p>
                                            <p v-if="memberData.preferences.gdprConsent.state=='REJECTED'">
                                                Souhlas jsi <strong>zamítl</strong> {{memberData.preferences.gdprConsent.date | formatCZDateNoHtml}} na 
                                                <span v-if="memberData.preferences.gdprConsent.form='app'">webu</span>
                                            </p>
                                            <p v-if="(!memberData.preferences.gdprConsent.state)||(memberData.preferences.gdprConsent.state=='UNKNOWN')">
                                                Zatím jsi souhlas neudělil ani neodmítl.
                                            </p>
                                        </v-list-item>
                                        <v-list-item>
                                            <a href="/public/_docs/panenka-gdpr.pdf" target="_blank">Dokument se souhlasem</a>
                                        </v-list-item>
                                    </v-list>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex d-flex>
                                    <v-btn color="gray" @click="updateMemberData">Zrušit změny</v-btn>
                                    <v-btn color="info" @click="saveMemberData" :disalbled=isSaving>
                                        <span v-if="isSaving"><loader size=40 colot="white"></loader></span>
                                        <span v-else>Uložit změny</span>
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                            <v-spacer/>
                            
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
        <div v-else>
            <loader size=200 color="orange"/>
        </div>

    </v-container>
</template>

<script>
    var logger = require("../fb-logger.js")
    import friendForm from '@/components/friendForm'
    const fb = require('../firebaseConfig.js')
    const dbPersons = require('../db-persons.js')
    const helpers = require('../helpers.js')
    
    import {
        mapState
    } from 'vuex'
    const membersCollection = fb.membersCollection
    const friendsCollection = fb.friendsCollection

    export default {
        components: {
             'friend-form':friendForm,

        },
        data() {
            return {
                valid: false,

                uid: null,
                memberData: null,
                groupMembers: [],
                canBeSubscribedBy: [],
                canSubscribe: [],
                friends: [],

                newCanBeSubscribedBy: null,
                allMembers: [],
                allMembersNames: [],

                newPhone: '',

                isSaving: false,
                isEditing1: true,

                editedFriend: null,
                friendEditMode: "ADD",

                //form validation
                nameRules: [
                    v => !!v || 'Musí být vyplněné',
                    v => v.length <= 10 || 'Name must be less than 10 characters'
                ],
                emailRules: [
                    v => !!v || 'Musí být vyplněné',
                    v => /.+@.+/.test(v) || 'E-mail must be valid'
                ],
                notEmpty: [
                    v => !!v || 'Musí být vyplněné',
                ]
            }
        },
        watch: {
             userProfile: function () {
                 this.extractUserProfileToLocal()
             }
        },
        computed: {
            ...mapState(['currentUser', 'userProfile', 'userLoading']),
        },
        methods: {
            updateGdpr(){
                this.$log.debug("processing GDPR")
                this.memberData.preferences.gdprConsent.date= new Date()
                this.memberData.preferences.gdprConsent.form="app"
            },
            addNewPhone() {
                this.memberData.phones.push(this.newPhone);
                this.newPhone = '';
            },
            deletePhone(index) {
                var out = []
                for (var i = 0; i < this.memberData.phones.length; i++) {
                    if (i != index) out.push(this.memberData.phones[i])
                }
                this.memberData.phones = out;
            },
            addCanBeSubscribedBy(member) {
                this.canBeSubscribedBy.push(member)
                this.saveCanBeSubscribedBy()
            },
            addNewCanBeSubscribedBy() {
                logger.info("[PERSONAL SETTINGS] Adding new canBeSubscribedBy:", this.newCanBeSubscribedBy)
                this.canBeSubscribedBy.push(this.allMembers[this.newCanBeSubscribedBy])
                this.newCanBeSubscribedBy = null;
                this.saveCanBeSubscribedBy()
            },
            deleteCanBeSubscribedBy(index) {
                let out = []
                for (var i = 0; i < this.canBeSubscribedBy.length; i++) {
                    if (i != index) out.push(this.canBeSubscribedBy[i])
                }
                this.canBeSubscribedBy = out;
                this.saveCanBeSubscribedBy()
            },
            saveCanBeSubscribedBy() {
                let out = []
                this.canBeSubscribedBy.forEach((item) => {
                    out.push(item.id)
                })
                this.memberData.canBeSubscribed = out
                this.saveMemberData()
            },
            saveMemberData() {
                this.isSaving = true;
                
                this.memberData.name.print = this.memberData.name.first + " " + this.memberData.name.last + " " + this.memberData.name.apendix
                this.memberData.name.print = this.memberData.name.print.trim()

                delete this.memberData.friendsExpanded;
                delete this.memberData.canBeSubscribedExpanded;
                delete this.memberData.canSubscribeExpanded;
                delete this.memberData.groupMembersExpanded;

                fb.membersCollection.doc(this.uid).set(this.memberData).then((snapshot) => {
                    this.isSaving = false;
                    this.$store.dispatch('fetchUserProfile')
                }).then(() => {
                    this.updateMemberData();
                })
            },
            setEditedFriend(friend) {
                this.$log.debug("Set editedFriend to:",friend)
                this.editedFriend = friend
                this.friendEditMode = "UPDATE"
            },
            addNewFriend() {
                this.$log.debug("Add friend to:",)
                this.editedFriend = null
                this.friendEditMode = "ADD"
            },

            updateMemberData() {

                this.extractUserProfileToLocal()
            },
            getAllMembers() {
                dbPersons.getAllMembers().then((members)=>{
                    this.allMembers = dbPersons.prepareAllMembersById(members) 
                    this.allMembersNames = dbPersons.prepareMemberNamesList(members)
                })
            },
            //make local copy
            extractUserProfileToLocal() {
                if (!this.userLoading) {
                    this.memberData = JSON.parse(JSON.stringify(this.userProfile));
                    this.uid = this.memberData.id
                    this.getAllMembers()
                    this.groupMembers = this.memberData.groupMembersExpanded
                    this.canBeSubscribedBy = this.memberData.canBeSubscribedExpanded
                    this.canSubscribe = this.memberData.canSubscribeExpanded
                    this.friends = this.memberData.friendsExpanded
                }
            }
        },
        mounted() {
            this.extractUserProfileToLocal()
        },
        filters: helpers.filters
    }
</script>