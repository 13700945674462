<template id="passwordReset">
  <v-container fluid grid-list-md>
    <v-layout row wrap>
      <v-flex d-flex xs12 sm12 md12>
        <v-layout row wrap>
          <v-flex d-flex>
            <!-- karta loginu -->
            <v-card class="elevation-12">
              <v-form v-model="valid" lazy-validation>
              <v-toolbar dark color="amber">
                <v-toolbar-title>Reset hesla na panenku</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
              <transition name="fade">
                <v-alert v-if="errorMessage" :value="true" type="error">
                  {{errorMessage}}
                </v-alert>
              </transition>
              <v-card-text>
                <v-alert type="success" :value=true v-if="passwordResetSuccess">
                  Heslo bylo úspěšně změněno. <v-btn @click="$router.push('/')"> Přejít na login</v-btn>
                </v-alert>
                  <v-text-field v-model.trim="newPassword" id="password" prepend-icon="lock" name="password"
                    label="Nové heslo" type="password" required :rules="notEmptyRules">
                  </v-text-field>
                  
                  <v-text-field v-model.trim="newPassword2" id="password2" prepend-icon="lock" name="password"
                    label="Nové heslo pro kontrolu" type="password" @keyup.enter.native="setNewPassword" required :rules="notEmptyRules">
                  </v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" @click="setNewPassword" :disabled="!valid">Nastavit nové heslo</v-btn>
              </v-card-actions>
              </v-form>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  const fb = require('../firebaseConfig.js')
  import { mapState } from 'vuex'
  const logger=require('../fb-logger.js')

  export default {
    name: 'passwordResetPage',
    data() {
      return {
        
        passwordResetSuccess: false,
        errorMessage: undefined,
        verificationCode: undefined,
        newPassword: undefined,
        newPassword2: undefined,

        valid: false,
        notEmptyRules:[
             v => !!v || 'Musí být vyplněné',
             v => !!v || v.length >= 6 || 'Heslo musí být delší než 6 znaků'
        ],
      }
    },
    computed:{
      ...mapState(['currentUser', 'userProfile', 'userLoading']),
    },
    methods: {
      setNewPassword(){
        logger.info("Setting new password")
        this.errorMessage = ""
        this.passwordResetSuccess = false;

        if(this.newPassword != this.newPassword2){
          logger.info("Passwords don not match")
          this.errorMessage = "Hesla se neshodují."
          return;
        }

        fb.auth.confirmPasswordReset(this.verificationCode, this.newPassword)
          .then(()=> {
            logger.info("Password reset success");
            this.passwordResetSuccess=true;
        })
        .catch((err)=> {
            logger.error("Password reset error: "+ err.message)
            this.errorMessage = "Chyba. "+err.message;
        })


      }
    },
    mounted(){
      this.verificationCode = this.$route.query.oobCode;
      //TODO: verify code
    }
  }
</script>