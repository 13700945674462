<template id="friendsPage">
  <div>
    <v-row>
      <v-col sm="5">
        <span class="text-h3">Seznam kamarádů</span>
      </v-col>
      <v-col></v-col>
      <v-col sm="4">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Hledej.."
          single-line
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12">
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="friends"
          hide-actions
          class="elevation-1"
          items-per-page=200
          :search="search"
        >
          <template v-slot:item.grantedBy="{ item }">
            {{ item.grantedByExpanded.name.print }}
          </template>

        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script type="text/javascript">
const fb = require("../firebaseConfig.js");
import { mapState } from "vuex";

export default {
  name: "memberPage",
  data() {
    return {
      loading: false,
      search: "",
      friends: [],
      headers: [
        { text: "Jméno", value: "name.first" },
        { text: "Příjmení", value: "name.last" },
        { text: "Dítě", value: "child" },
        { text: "Kamarád od", value: "grantedBy" },
      ],
    };
  },
  methods: {
    getFriendList() {
      this.loading=true
      fb.friendsCollection.get().then((snapshot) => {
        this.members = [];
        this.$log.debug("Expanding friends...");
        snapshot.forEach((friendSnap) => {
          var friend = friendSnap.data();
          //this.$log.debug(friend)
          fb.membersCollection
            .doc(friend.grantedBy)
            .get()
            .then((member) => {
              friend.grantedByExpanded = member.data();
              this.friends.push(friend);
            });
        });
        this.loading=false
      });
    },
  },
  mounted() {
    this.getFriendList();
  },
};
</script>
